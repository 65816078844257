export const environment = {
  production:  true,
    baseApiURLprod: 'https://api.kindi.sa.com/api/',
    baseApiURL: 'https://api.kindi.sa.com/api/',
  imageUrl:'https://ntoa-nota-public.s3.us-west-2.amazonaws.com/widget',
  toastrConfig: {
    maxOpened: 1,
    onActivateTick: true,
    progressBar:  false,
    preventDuplicates: true,
    disableTimeOut:  false,
    timeOut: 3000, // 15 seconds
    extendedTimeOut: 100,   
  },
};
