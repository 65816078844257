
<div class="nota-sub" [ngClass]="{'active': isNotaSubActive}" *ngIf="subSection">
  <img (click)="closesection()" alt="Close icon"  class="close-section" src="../../../../assets/images/close.png">
  <div class="container">
<p class="mt-20">البقاء على علم! اشترك في النشرة الإخبارية لدينا واحصل على أحدث الأسعار والعروض الحصرية التي يتم تسليمها إلى بريدك الإلكتروني.</p>
<form [formGroup]="Createsubform" (submit)="Createsubform.valid && Createchatforms()" class="dona-sub">
  <div class="div-sub">
  <div class="input-group mb-20">
    <input dir="ltr" class="floating-input" placeholder="ادخل بريدك الالكتروني" type="email" formControlName="email" required>
    <div class="feedback"  *ngIf="Createsubform.controls['email'].dirty && Createsubform.controls['email'].hasError('pattern')">{{validationMessage.INVALID_EMAIL}}</div>
    <div class="feedback"  *ngIf="Createsubform.controls['email'].dirty && Createsubform.controls['email'].hasError('required')">{{validationMessage.EMAIL_REQUIRED}}</div>
    <hr class="line-input">
  </div>
  <div class="text-align-right">
      <button type="submit" class="btn primary pl-25 pr-25 btn-sub" 
    >اشتراك</button>
    <!-- <h6 class="mt-20">بالضغط على اشتراك انت توافق على <a [href]="['/terms']">الشروط</a> و <a [href]="['/privacy']">الخصوصية </a></h6> -->
    <h6 class="mt-20">بالضغط على اشتراك انت توافق على <a >الشروط</a> و <a >الخصوصية </a></h6>
  </div>
</div>
</form>
</div>
