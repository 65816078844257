import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {
  menuCrossIcon = false;
  constructor(private titleService: Title) { }
  ngOnInit(): void {
    this.titleService.setTitle('About Dona - Dona AI');
  }

  menuOpen() {
    setTimeout(() => {
      this.menuCrossIcon = !this.menuCrossIcon;
    }, 500);
  
  }

}
