<div class="section">
<!-- <a class="footer-brand" href="/">
  <img class="footer-brand" src="../../../../assets/images/dona.png" alt="Logo" />
  </a> -->
<hr>
<footer id="contact">
  <div class="footer desktop">
    <div class="footer-link">
    <p>© Dona 2023 - 2024 </p>
    <!-- <p><a [href]="['/privacy']"> privacy </a></p>
    <p><a [href]="['/terms']"> terms </a></p> -->
    <!-- <p><a [href]="['/about-us']"> about DONA </a></p>  -->
    <p><a [href]="['/patiner']"> work with us  </a></p>
    <p><a class="text-with-underline" [href]="['/ar']"> العربية</a></p>
  </div>
  </div>

  <div class="footer mobile">
  
  </div>

  <div class="footer ipad">

  </div>
</footer>  
</div>


  <!-- Choose your fragrance, Select your perfume, Select your fragrance, Best perfume choice, Perfumes best seller, Niche perfumes, Perfumes stores, Fragrance stores, Perfumes technology, Perfumes recommendations, Perfumes recommendations system, AI for perfumes -->
  <!-- Perfume assistant, choose perfume, Choose fragrance, Select perfume, Select fragrance, Best perfume choice, perfumes best seller, niche perfumes, perfumes stores, Fragrance stores, perfumes technology, perfumes recommendations, perfumes recommendations system, AI for perfumes -->