import { HttpClient, HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, map } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { Application } from '../enum/application.enum';
import { CommonService } from '../shared/services/common.service';
const httpOptions = {
  headers: new HttpHeaders({
    'Accept': 'application/json',
    'domain-name' :window.location.origin,
   
  }),

  body: '',
  observe: ''

};
const formOptions = {
  headers: new HttpHeaders()
};
let baseApiURL: string = environment.baseApiURL;

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  loaderId = '#reqestLoader';
  public diaplayLoader$ = new Subject<any>()
  constructor(
    private readonly http: HttpClient,
    private readonly commonService: CommonService
  ) { }

  /**
  * @description For handle http request.
  * @param type request type
  * @param apiName API end point
  * @param showLoader loader status
  * @param data  request body
  */

  httpRequest(
    type: string,
    apiName: string,
    showLoader: boolean,
    data?: string | undefined,
    newBaseApiURL?: string | undefined
  ): Observable<any> {

    if (showLoader === true) {
      this.loaderToggle(true);
    }
    if (newBaseApiURL){
      baseApiURL = newBaseApiURL;
    }else{
      baseApiURL = environment.baseApiURL;
    }
    if (this.commonService.getLocalStorageItem(Application.TOKEN)) {
      const tokenObj = this.commonService.getLocalStorageItem(Application.TOKEN);
      httpOptions.headers = httpOptions.headers.set('Authorization', `Bearer ${String(tokenObj)}`);
    }
    if (localStorage.getItem("notaEmail")) {
      httpOptions.headers = httpOptions.headers.set('customer-detail', `${localStorage.getItem("notaEmail")}`);
   }
   else{
     httpOptions.headers  = httpOptions.headers.set('customer-detail', '');
   }

    if (type === 'DELETE' && data) {
      httpOptions.body = data;
    }

    if (type === 'DOWNLOAD') {
      httpOptions.observe = 'response'
    }

    switch (type) {
      case Application.GET:
        return this.get(apiName, showLoader, httpOptions);
      case Application.POST:
        return this.post(apiName, showLoader, httpOptions, data);
      case Application.PUT:
        return this.put(apiName, showLoader, httpOptions, data);
      case Application.DELETE:
        return this.delete(apiName, showLoader, httpOptions);
      case Application.PATCH:
        return this.patch(apiName, showLoader, httpOptions, data);
        case Application.DOWNLOAD :
        return this.get(apiName, showLoader, httpOptions);
    }
  }

  /**
  * @description Multipart request (form data)
  * @param type  Request type
  * @param apiName  API end point
  * @param showLoader Loader status
  * @param data Request data.
  */
  formRequest(
    type: string,
    apiName: string,
    showLoader: boolean,
    data?: string | undefined
  ): Observable<any> {
    
    if (showLoader === true) {
      this.loaderToggle(true);
    }
    if (this.commonService.getLocalStorageItem(Application.TOKEN)) {
      const tokenObj = this.commonService.getLocalStorageItem(Application.TOKEN);
      httpOptions.headers = httpOptions.headers.set('Authorization', `Bearer ${String(tokenObj)}`);
    }
    switch (type) {
      case 'GET':
        return this.get(apiName, showLoader, formOptions);
      case 'POST':
        return this.post(apiName, showLoader, formOptions, data);
      case 'PUT':
        return this.put(apiName, showLoader, formOptions, data);
      case 'DELETE':
        return this.delete(apiName, showLoader, formOptions);
      case 'PATCH':
        return this.patch(apiName, showLoader, formOptions, data);
    }
  }
  get(apiName: string, showLoader: boolean, options: any) {
    return this.http.get(`${baseApiURL}${apiName}`, options)
      .pipe(map(res => {
        if (showLoader) {
          this.loaderToggle(false);
        }
        return res;
      }),
      );
  }
  post(apiName: string, showLoader: boolean, options: any, data: string | undefined) {
    return this.http.post(`${baseApiURL}${apiName}`, data, options)
      .pipe(map(res => {
        if (showLoader) {
          this.loaderToggle(false);
        }
        return res;
      }));
  }
  put(apiName: string, showLoader: boolean, options: any, data: string | undefined) {
    return this.http.put(`${baseApiURL}${apiName}`, data, options)
      .pipe(map(res => {
        if (showLoader) {
          this.loaderToggle(false);
        }
        return res;
      }));
  }
  patch(apiName: string, showLoader: boolean, options: any, data: string | undefined) {
    return this.http.patch(`${baseApiURL}${apiName}`, data, options)
      .pipe(map(res => {
        if (showLoader) {
          this.loaderToggle(false);
        }
        return res;
      }));
  }
  delete(apiName: string, showLoader: boolean, options: any) {
    return this.http.delete(`${baseApiURL}${apiName}`, options)
      .pipe(map(res => {
        if (showLoader) {
          this.loaderToggle(false);
        }
        return res;
      }),
      );
  }


  /**
* @description manage loader status on api call
*/
  public loaderToggle(val: boolean) {
    this.diaplayLoader$.next(val);
  }
  
}
