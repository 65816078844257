<app-navbar></app-navbar>
<section>
  <div class="container feature-container">
    <div id="overview" class="feature-heading" >
      <div class="common-tag">
      </div>
      <h2 class="feature-title text-black">كيف احصل على عملاء"جادين" 
      </h2>
      <img src="../../../../assets/images/black-star.svg" alt="Star" class="black-star"/>
      <img src="../../../../assets/images/black-perfume.svg" alt="Perfume" class="black-perfume"/>
    </div>
  
    <div class="row feature-row" >
      <div class="col-md-5 feature-left">
        <div class="feature-media">
          <div class="d-flex feature-body">
            <div class="flex-shrink-0 feature-img">
              <img src="../../../../assets/images/black-search.svg" alt="Search" class="feature-media-pic">
            </div>
            <div class="flex-grow-1 feature-des">
              <h5> يدخل العميل لموقع دونا 
              </h5>
              <p>سيقوم دونا بالعمل على الاستحواذ على العملاء حيث يدخل العملاء لموقع دونا عبر الإعلانات التي تقوم بها دونا 
              </p>
            </div>
          </div>
        </div>
        <div class="feature-media">
          <div class="d-flex feature-body">
            <div class="flex-shrink-0 feature-img">
              <img src="../../../../assets/images/black-add.svg" alt="Search" class="feature-media-pic">
            </div>
            <div class="flex-grow-1 feature-des">
              <h5> يضيف العميل عطوره المفضلة                 </h5>
              <p>تحتاج دونا لبناء تصور لذائقة العميل العطرية من خلال إضافة العميل لـ ٣ من عطوره المفضلة
              </p>
            </div>
          </div>
        </div>
        <div class="feature-media">
          <div class="d-flex feature-body">
            <div class="flex-shrink-0 feature-img">
              <img src="../../../../assets/images/black-checked.svg" alt="Search" class="feature-media-pic">
            </div>
            <div class="flex-grow-1 feature-des">
              <h5>ستقترح دونا العطور الأنسب للعميل               </h5>
              <p>سوف تقترح دونا مجموعة من العطور الأكثر مناسبة لذوق العميل، وسوف يتم عرض مجموعة المواقع التي توفر العطر بحسب السعر المحدد من الموقع
              </p>
            </div>
          </div>
        </div>
        <div class="feature-media">
          <div class="d-flex feature-body">
            <div class="flex-shrink-0 feature-img">
              <img src="../../../../assets/images/black-checked.svg" alt="Search" class="feature-media-pic">
            </div>
            <div class="flex-grow-1 feature-des">
              <h5> إتمام الشراء في موقعك الالكتروني              </h5>
              <p>يقوم العميل باختيار الموقع الأنسب بالنسبة له، وتقوم دونا بتحويل العميل لموقعك حيث يتقوم باتمام الشراء عبر موقعكم الالكتروني</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-7 feature-right">
        <img src="../../../../assets/images/feature-pic.png" alt="main-img" class="Feature pic"/>
      </div>
    </div>
  </div>
  </section>
  
  <section id="installation">
  <div class="installation desktop">
    <div class="common-tag">
      <!-- <span>Overview</span> -->
    </div>
    <h2 class="feature-title text-black">لماذا دونا ؟  </h2>
    <div class="container installation-container">
      <div class="row installation-wrap" >
        
        <div class="col-md-4 card-wrap">
          <div class="card-img">
            <!-- <img src="../../../../assets/images/get-your-code.png" alt="main-img" class="card-pic"/> -->
            <!-- <a class="code-pic" (click)="getYourCode(sharableLink)"><img src="../../../../assets/images/code-icon.svg"
                                                                         alt="Code pic"/></a> -->
          </div>
          <a>
            نساعد العميل على اختيار العطور</a>
          <p>
            يقوم دونا بترشيح عطور بناء على الذائقة العطرية للعميل  

          </p>
        </div>
        <div class="col-md-4 card-wrap">
          <div class="card-img">
            <!-- <img src="../../../../assets/images/get-your-code.png" alt="main-img" class="card-pic"/> -->
            <!-- <a class="code-pic" (click)="getYourCode(sharableLink)"><img src="../../../../assets/images/code-icon.svg"
                                                                         alt="Code pic"/></a> -->
          </div>
          <a>توفير نظام استبدال لامثيل له  </a>
          <p>
            في حال قام العميل بشراء عطر من خلال نظام الترشيحات في دونا، فإننا نقدم للعميل خيار ارجاع العطر ودفع قيمة العطر من قبل دونا حتى لو تم استخدام العطر

          </p>
        </div>
    
        <div class="col-md-4 card-wrap">
          <div class="card-img">
            <!-- <img src="../../../../assets/images/advisor.png" alt="main-img" class="card-pic"/>
            <a class="code-pic"><img src="../../../../assets/images/like.svg" alt="Code pic"/></a> -->
          </div>
          <a>دعنا نسوق لمنتجاتكم          </a>
          <p>
            يقوم دونا بالترويج لمنتجاتكم العطرية للعملاء المناسبين، باستخدامك لدونا فأنت تزيد ظهورك لعملاء أكبر
          </p>
        </div>
      </div>
  
      <form action="/action_page.php" style="opacity: 0; position: absolute;">
                <textarea #sharableLink>
                    <base href="/">
  
                    <Nota-widget >
                    </Nota-widget>
                  </textarea>
        <br>
        <input type="submit" value="Submit">
      </form>
  
    </div>
  </div>
  

  </section>
  
  <section>
  <div id="faq" class="relax">
    <div class="container relax-container">
      <div class="row relax-wrap">
        <div class="col-md-6 relax-left">
          <!-- <img src="../../../../assets/images/chat.svg" alt="main-img" class="chat-icon"/> -->
          <div class="common-tag">
            <span>اطرح سؤلا بشكل متكرر</span>
          </div>
          <h2 class="relax-title">نحن هنا من اجلك</h2>
          <p>اذا كان لايزال لديك اسئلة اضافية يرجى التواصل معنا من خلال البريد الالكتروني<a href="mailto: info@nota-nota.com">info&#64;nota-nota.com</a></p>
        </div>
        <div class="col-md-6 relax-right">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button class="accordion-button" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        ماهي رسوم استخدام دونا ؟

                </button>
              </h2>
              <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                   data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  يقوم دونا بالعمل كمسوق بالعمولة ضمن نظام “التسويق بالعمولة” بنسبة ٥٪ من المشتريات التي يتم توجيها عبر الرابط الموجه من دونا

                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        كيف يتم احساب العمولة ؟

                </button>
              </h2>
              <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                   data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  يقوم نظام “التسويق بالعمولة” بشكل اوتوماتيكي بتحديد العملاء الذين تم توجههم من موقع دونا، وإحساب قميه عمليات الشراء التي تمت عبر الرابط خلال مدة محددة من تاريخ استخدام الرابط، ثم يقوم باحتساب قيمة العمولة الخاصة بدونا بشكل أوتوماتيكي

                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        ماهي فكرة نظام الذكاء الصناعي لترشيح العطور؟ 

                </button>
              </h2>
              <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                   data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  يقوم نظام دونا بترشيح عطور للعملاء من خلال ادخال العميل للعطور التي يفضلها، ثم يقوم نظام الذكاء الصناعي بتحليل تلك العطور وخصائصها العطرية من خلال قاعدة بيانات خاصة بدونا، ثم يقوم باقتراح ٣ عطور من قاعدة البيانات تحمل خصائص عطرية يفضلها العميل

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  </section>
  <app-footer></app-footer>

  <!-- اختر عطرك, اختيار عطور, انسب عطر, افضل عطر, عطور نيش, تقنية عطور, رشح عطر, ترشيح عطور, عطر مرشح, نظام ترشيح عطور, اختبار عطور, عطرك المفضل, عطر, عطور, الذكاء الاصطناعي للعطور, عطور الاعلى مبيعًا, متجر عطور -->
<!-- مساعدة في اختيار عطر, عطر على ذوقي, رشح لي عطر, كيف أختار عطر, اختر عطرك, اختيار  عطور, انسب عطر, افضل عطر, عطور نيش, تقنية عطور, رشح عطر, ترشيح عطور, عطر مرشح, نظام ترشيح عطور, اختبار عطور, عطرك المفضل, عطر, عطور, الذكاء الاصطناعي للعطور, عطور الاعلى مبيعًا, متجر عطور -->