import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild, ViewEncapsulation , } from '@angular/core';
import { Router } from '@angular/router';
import { VALIDATION_MESSAGE } from 'src/app/enum/constraints';
import { BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import { ToastService } from 'src/app/shared/services/toast.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { environment } from 'src/environments/environment';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthHttpServiceService} from '../../landing-page/services/auth-http-service.service';
import { AuthService } from '../../../core/auth.service';
import { DashboardServiceService } from '../../authenticate/dashboard-service.service';
import { REGEX } from 'src/app/enum/validation';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  islogin= false;
  password1;
  notlogin = false;
  firstlogin = true;
  error = false;
  loginForm: UntypedFormGroup;
  login= false;
  forgetpass =false;
  createacc = false;
  isUserLoggedIn = false;
  show = false;
  localnotaEmail;
  existsEmail = true;
  isLoggedIn: boolean;
  validationMessage = VALIDATION_MESSAGE;
  CreateuserFrm: UntypedFormGroup;
  forgettpassForm: UntypedFormGroup;
  constructor(private readonly formBuilder: UntypedFormBuilder ,
    public modalRef: BsModalRef,
    private ref: ChangeDetectorRef,public readonly service:DashboardServiceService ,
    public modalService: BsModalService, private readonly router: Router,public readonly authService: AuthHttpServiceService, public commonService: CommonService, private readonly toastService: ToastService,private readonly AuthService: AuthService ) { 
     this.isLoggedIn = AuthService.isUserLoggedIn();
    }

    ngOnInit(): void {
      this.CreateuserFrm = this.formBuilder.group({
        full_name: ['', [Validators.required]],
        email: ['', [Validators.required, Validators.pattern(REGEX.EMAIL)]],
        phone: ['', [Validators.required]],
        password: ['', [Validators.required]],
        }
        );
        this.loginForm = this.formBuilder.group({
          email: ['', [Validators.required, Validators.pattern(REGEX.EMAIL)]],
          password: ['', Validators.required],
          rememberMe: false
        }
        );
        this.forgettpassForm = this.formBuilder.group({
          email: ['', [Validators.required, Validators.pattern(REGEX.EMAIL)]],
        }
        );
    }


    Createuserform() {
      let data = {
        full_name: this.CreateuserFrm.value.full_name,
        email: this.CreateuserFrm.value.email,
        phone: this.CreateuserFrm.value.phone,
        password: this.CreateuserFrm.value.password,
      }  
      this.service.creatuser(data)
      .subscribe((response) => {
        this.commonService.setLocalStorage(response);
        this.login = !this.login;  
        const email = this.CreateuserFrm.value.email;
        const password = this.CreateuserFrm.value.password;
        this.loginautomatically(email, password);
      }, (error) => {
        const errorMessage = error.toString(); 
        if (errorMessage.includes('The user with this email already exists in the system.')) {
          this.existsEmail = true; 
          this.existsEmail = !this.existsEmail; 
        } 
      }
      
      
      
        
      );
    }
    loginautomatically(email: string, password: string) {
      const formData: any = new FormData();
      formData.append("username", email);
      formData.append("password", password);
       this.service.loginuser(formData)
        .subscribe((response) => {
          location.reload()
          if (response){
            localStorage.setItem("email", email);
            localStorage.setItem("user_id", password);
            this.commonService.setLocalStorage(response)
            this.commonService.setLocalStorage(response)
            this.login = !this.login;       
            localStorage.setItem("email", email);
            this.localnotaEmail = localStorage.getItem("notaEmail")  
            const token = localStorage.getItem("access_token");
            if (token) {
              this.isUserLoggedIn = true;
            }
  
          }
  
        },
        (error) => {
          console.error('Login failed', error);
          this.error = true;
          this.firstlogin = false;
          // Perform any other actions you need in response to the error
        }
        );
    }
    onClick() {
      if (this.password1 === 'password') {
        this.password1 = 'text';
        this.show = true;
      } else {
        this.password1 = 'password';
        this.show = false;
      }
    }

    loginform() {
      const formData: any = new FormData();
      formData.append("username", this.loginForm.value.email);
      formData.append("password", this.loginForm.value.password);
       this.service.loginuser(formData)
        .subscribe((response) => {
          location.reload()
          if (response){
            localStorage.setItem("email", this.loginForm.value.email);
            localStorage.setItem("user_id", response.user_id);
            this.commonService.setLocalStorage(response)
            this.commonService.setLocalStorage(response)
            this.login = !this.login;       
            localStorage.setItem("email", this.loginForm.value.email);
            this.localnotaEmail = localStorage.getItem("notaEmail")  
            const token = localStorage.getItem("access_token");
            if (token) {
              this.isUserLoggedIn = true;
            }
  
          }
  
        },
        (error) => {
          console.error('Login failed', error);
          this.error = true;
          this.firstlogin = false;
        }
        );
  
  
    }
  Creatuser() {
  this.login = !this.login;
  this.createacc = true;
  this.createacc = this.createacc;
  }

  loginforms(){
    this.login = !this.login;
    this.createacc = !this.createacc
  }


}
