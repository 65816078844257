<app-navbar></app-navbar>
<section id="installation">
  <div class="installation desktop">
    <div class="installation-container">
      <div class="row installation-heading search-list-nota-nota row feature-row">
        <div>
        <form class=" container">
          <button class="default-pic"  (click)="nextscreenfirst(showProductonFirstSCreen)" *ngIf="showProductonFirstSCreen?.length > 2"
            [disabled]="!(showProductonFirstSCreen.length > 2)"
            [ngClass]="{'black-button': showProductonFirstSCreen.length > 2}"
            >Next</button>
                      <div class="form-group mb-20 container">
            <p *ngIf="clickperfume">Pick at least 3 favorites to unlock your match!</p>
            <h3>Search for your favorite fragrances</h3>
            <input dir="ltr" type="search" placeholder="Search by perfume name" [formControl]="searchInputControl" />
            <!-- <img class="search-cross" src="/assets/icons/search-cross.svg" alt="Search cross" (click)="crossSearchScreen()" /> -->
          </div>
         <div class="div-perfume" *ngIf="perfumediv">
          <h3>Add <span class="highlight"> 3 to 5</span> of your favorite perfumes </h3>
          <h3 #alertMessage class="alert-message" *ngIf="more5"> You can add only 5 perfumes </h3>
          <div class="default-pic-wrp-mobile">
  </div>
          <hr/>
          <div dir="ltr" class="perfume-listing container">
            <div dir="ltr" class="perfume-listing-wrap "
              *ngFor="let data of searchData" (click)="add(data)" [ngClass]="idGetter(data?._id)? 'disableClass' : '' " >
              <div dir="ltr" class="perfume-info">
                <div class="plus-icon"><img alt="Add icon" class="add-arrow"  src="../../../../assets/icons/{{idGetter(data?._id) ? 'click-btn.png' : 'add-per.png'}}"></div>
                <!-- <div class="fake-checkbox-container"><input type="checkbox"><div class="fake-checkbox"></div></div> -->
                <div class="perfume-pic">
                  <img class="right-arrow" src="{{data?.image_url}}"  onerror="this.src='https://ntoa-nota-public.s3.us-west-2.amazonaws.com/widget/assets/images/default.png';"  alt="Perfume pic" />
                </div>
                <div class="perfume-des">
                  <h6 class="perfume-title"  >{{data?.name}} </h6>
                  <p class="perfume-text">{{data?.brand}}</p>
                </div>
              </div>
              <div class="plus-icon">
              <div class="flex flex-row items-center justify-start leading-typeTag font-primary">
          </div>
            </div>
            
            </div>  
            <div class="spinner-nota-nota" *ngIf="loader">
              <div class="loader-nota-nota"></div>
              <div class="loader-overlay-nota-nota"></div>
            </div>

            <div class="width-100">
              <div class="recommended-null" *ngIf="!loader">
                <div class="no-perfume-found request-this-perfume" *ngIf="searchData?.length ===0 && noDataFirstTime">
                  <h5 class="no-title">Oops... no perfume found</h5>
                  <p class="no-text">Seems like this perfume is not in our list yet.</p>
                  <button class="no-btn btn primary pl-25 pr-25"
                     (click)="openSuggest()">Suggest Perfume</button>
                </div>
              </div>
            </div>
            
          </div>
        </div>
        </form>
      </div>
  
      </div>
    </div>
  </div>

  <!-- <app-chat-widght></app-chat-widght> -->
  <app-footer></app-footer>


    <!-- Choose your fragrance, Select your perfume, Select your fragrance, Best perfume choice, Perfumes best seller, Niche perfumes, Perfumes stores, Fragrance stores, Perfumes technology, Perfumes recommendations, Perfumes recommendations system, AI for perfumes -->
  <!-- Perfume assistant, choose perfume, Choose fragrance, Select perfume, Select fragrance, Best perfume choice, perfumes best seller, niche perfumes, perfumes stores, Fragrance stores, perfumes technology, perfumes recommendations, perfumes recommendations system, AI for perfumes -->