import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/core/http.service';
import { HttpServiceda } from 'src/app/core/http.service-da';
import { Application } from 'src/app/enum/application.enum';
import { CommonService } from 'src/app/shared/services/common.service';
import { format } from 'src/assets/utils/index';
import { Observable } from 'rxjs';




@Injectable({
  providedIn: 'root'
})
export class AuthHttpServiceService {
  private urlParams: string;
  constructor( private readonly httpService: HttpService,
    private readonly commonService: CommonService,
    private readonly httpServiceda: HttpService,
    ) { }
  public login(data: any) {
    const url = Application.LOGIN;
    return this.httpService.httpRequest(Application.POST, url, true, data);
}


UserData(id) {
  const url = "users/"+ id
  return this.httpServiceda.httpRequest('GET', url,  false);
}
public  widgetProduct(query,offset, limit) {
  const url = `${Application.PRODUCT}search?query=${query}&limit=${limit}&offset=${offset}`;
  return this.httpService.httpRequest(Application.GET, url, true);
}
public signIn(website,email) {
  const url = `${Application.SignIn}website=${website}&email_or_phone=${email}`
  return this.httpService.httpRequest(Application.GET, url, true, );
}


public  favorite(data: any) {
  const url = Application.favorite;
  return this.httpService.httpRequest(Application.POST, url, true, data);
}

public perfumeAffiliateMangement(params){
  this.urlParams = this.commonService.setUrlParams(params);
  const url = `${Application.PERFUME_AFFILIATE_MANAGEMENT}${this.urlParams}`;
  return this.httpServiceda.httpRequest('GET', url, true);
}



public logincustomer(data){
  const url = `${Application.SUGGESTEDPERFUME}`
  return this.httpService.httpRequest(Application.POST, url, true, data);
}

public   StoreValidation(data){
  const url = `${Application.STORE}`
  return this.httpService.httpRequest(Application.POST, url, true, data);
}
public recommendation(id){
  const url = `${Application.RECOMMENDATION}?product_ids=${id}&max_rate=3&top_k=6`
  return this.httpService.httpRequest(Application.GET, url, true);
}




public customerDetails(data){
  const url = `${Application.CUSTOMER_SIGN_IN}`;
  return this.httpService.httpRequest(Application.POST, url, true, data);
}

}