import { ViewportScroller } from "@angular/common";
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LoginComponent } from "../login/login.component";
import { DEFAULT_PARAMS } from 'src/app/enum/constraints';
import { Component, Input, OnInit,ChangeDetectorRef, ViewChild, ElementRef, } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthHttpServiceService} from '../../landing-page/services/auth-http-service.service';
import { AuthService } from '../../../core/auth.service';
import { DashboardServiceService } from '../../authenticate/dashboard-service.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { GoogleTagManagerService } from 'angular-google-tag-manager';


@Component({
  selector: 'app-product-prices',
  templateUrl: './product-prices.component.html',
  styleUrls: ['./product-prices.component.scss']
})
export class ProductPricesComponent implements OnInit {
  price: any;
  sortBy: string = 'asc';
  sale: any;
  logo: any;
  link: any;
  store: any;
  Datacprices: any;
  id: any;
  priceDatanew: any[];
  priceId: any;
  idproduct: any;
  productname: any;
  productbrand: any;
  perfumedata: any;
  image: any;
  id_product: any;
  nameperfume: any;
removeFromFavorites($event: string) {
throw new Error('Method not implemented.');
}
  @Input() totalCount: number;
  fav: any;
  userid = localStorage.getItem("user_id");
  @ViewChild('specificButton') specificButton: ElementRef;
  super_admin = localStorage.getItem("is_super_admin");
  showWishlist: boolean = false;
selectedProducts: any[];
  toggleWishlist() {
    this.showWishlist = !this.showWishlist;
  }
  average: number;
  showfav = false;
  counter = 0;
  addfav = [];
  @Input() pageSize: number = 10;
  selcetperfume = true;
  isLoggedIn: boolean;
  showArrow = true;
  firstlogin = true;
  linkElement: HTMLElement;
  isLoggedin = true;
  lowPricesFilter: boolean;
  highPricesFilter: boolean;
  theContent= true;
  isClicked: string = '';
  infoPerfume= true;
  showRec= true;
  maxPages: number = 0;
  params = DEFAULT_PARAMS;
  FilterOption: boolean;
  minValue;
  matchedValues = [];
  priceData: any;
  idprodact = [];
  perfumeData: any;
  constructor(public service: DashboardServiceService,private readonly AuthService: AuthService,private ref: ChangeDetectorRef, public modalService: BsModalService,private readonly toastService: ToastService, private scroller: ViewportScroller, private router: Router
    ,public commonService: CommonService, private route: ActivatedRoute, private readonly authHttpService: AuthHttpServiceService,private readonly formBuilder: UntypedFormBuilder, private readonly DashboardServiceService: DashboardServiceService,public modalRef: BsModalRef,private titleService: Title, private gtmService: GoogleTagManagerService) {
      this.isLoggedIn = AuthService.isUserLoggedIn();

     }

  ngOnInit(): void {
    this.titleService.setTitle('الاسعار - Dona AI');
  this.route.queryParams.subscribe(params => {
   this.idproduct  = params['id'];
    const storedData = localStorage.getItem('perfumeData');
    if (storedData) {
      this.perfumeData = JSON.parse(storedData);
    }
    this.getStorePerfumeData();
       const productId = this.perfumeData['product._id'];
       this.productname = this.perfumeData['product.name_ar'];
       this.productbrand = this.perfumeData['product.brand_ar'];
    this.service.otherprice(this.idproduct, this.params.page_num, this.pageSize)
      .subscribe((res) => {      
    this.priceData = res.price_data;

    if (this.priceData && Array.isArray(this.priceData)) {
      this.priceDatanew = this.priceData.filter(item => item._id !== this.idproduct);
    }         
        this.totalCount = res.Total_count;
        this.average = res.average_rating;
        this.maxPages = Math.ceil(this.totalCount / this.pageSize);
      });

      const prices = this.perfumeData && this.perfumeData['product._id'];

  })
  }
  setSortOrder(order: string) {
    this.sortBy = order; 
    this.priceDatanew = this.priceData.filter(item => item._id !== this.idproduct);
    if (this.sortBy === 'asc') {
      this.priceDatanew.sort((a, b) => a.price - b.price); 
    } else if (this.sortBy === 'desc') {
      this.priceDatanew.sort((a, b) => b.price - a.price); 
    }
  }
  getStorePerfumeData() {
    this.service.dataperfume(this.idproduct).subscribe((res: any) => {
      if (res) {
        this.perfumedata = res;
        this.image = this.perfumedata.image_url
        this.nameperfume = this.perfumedata.name_ar
        ;
      }
    });

  }


  handleClick(option: string): void {
    if (this.isClicked === option) {
      this.isClicked = null;
      this.clearClick();
    } else {
      this.isClicked = option;
      if (option === 'LowPrices') {
        this.setSortOrder('asc');
      } else if (option === 'HighPrices') {
        this.setSortOrder('desc');
      } else if (option === 'Discount') {
        this.Discount();
      }
    }
  }
  

  extractNumber(priceString) {
    return priceString;

  }




    Perfumeinfo(data,cid) {
      localStorage.setItem('perfumeData-2', JSON.stringify(data));
      const id = this.idproduct;
      this.router.navigateByUrl(`ar/product-page?cid=${id}&id=${cid}`).then(() => {
        window.scrollTo(0, 0);
        window.location.reload();
      });
    }
    clearClick() {
      this.setSortOrder(this.sortBy); 
    }


  idfav(id) {
    return this.addfav.some(product => product?._id === id);
  }

  clickuser(event, item) {
    this.trackEvent('Product Link - prices page', 'Click', item.link, item.name, item.store, item._id);
    event.preventDefault();
    window.open(item, "_blank");
    if(!this.isLoggedIn){
        this.modalRef = this.modalService.show(LoginComponent, {
            initialState: {},
            class: 'perfume-edit-modal'
        });
    } else {
    }
}

trackEvent(eventCategory: string, eventAction: string, eventLabel: string, productName: string, partnerName: string, ProductID: string) {
  this.gtmService.pushTag({
    event: 'partner_website_click',
    eventCategory,
    eventAction,
    eventLabel,
    productName,
    partnerName,
    ProductID
  });
}

  removeProductFromFavorites(productId: string): void {
    const productIndex = this.selectedProducts.findIndex(product => product.id === productId);

    if (productIndex !== -1) {
      this.selectedProducts.splice(productIndex, 1);
      localStorage.setItem('selectedProducts', JSON.stringify(this.selectedProducts));
      this.ref.detectChanges();
    }
  }

  closesection(){
    this.showfav = !this.showfav;
  }
  opensection(){
    this.showfav = true;
  }

  toggleFilter() {
    this.FilterOption = !this.FilterOption
  }


  lowPricesFilters(){
    this.lowPricesFilter = true;
  }

  Discount() {
    if (this.priceData && Array.isArray(this.priceData)) {
      this.priceDatanew = this.priceData.filter(item => item.sale !== item.price && item._id !== this.idproduct);
    } 
  }

  highPricesFilters(){
    this.highPricesFilter = true;
  }
}
