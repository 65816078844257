import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PartnerComponent as arPartnerComponent } from './module/ar/partner/partner.component'
import { AboutUsComponent as enAboutUsComponent } from './module/landing-page/about-us/about-us.component';
import { AboutUsComponent as arAboutUsComponent } from './module/ar/about-us/about-us.component';
import { RecPageComponent as LandingPageRecPageComponent } from './module/landing-page/rec-page/rec-page.component';
import { RecPageComponent as ArRecPageComponent } from './module/ar/rec-page/rec-page.component';
import { ProductPageComponent as enProductPageComponent } from './module/landing-page/product-page/product-page.component';
import { ProductPageComponent as arProductPageComponent } from './module/ar/product-page/product-page.component';
import { SellectedPageComponent as LandingPageSellectedPageComponent } from './module/landing-page/sellected-page/sellected-page.component';
import { SellectedPageComponent as ArSellectedPageComponent } from './module/ar/sellected-page/sellected-page.component';
import { ProductPricesComponent as enProductPricesComponent} from './module/landing-page/product-prices/product-prices.component';
import { ProductPricesComponent as arProductPricesComponent} from './module/ar/product-prices/product-prices.component';
import {ProductNotFoundComponent as arProductNotFoundComponent} from './module/ar/product-not-found/product-not-found.component'
import {ProductNotFoundComponent as enProductNotFoundComponent} from './module/landing-page/product-not-found/product-not-found.component'
import { UserProfileComponent as enUserProfileComponent } from './module/landing-page/user-profile/user-profile.component';
import { UserProfileComponent as arUserProfileComponent } from './module/ar/user-profile/user-profile.component';
import { IngredientsComponent as enIngredientsComponent } from './module/landing-page/ingredients/ingredients.component';
import { IngredientsComponent as arIngredientsComponent } from './module/ar/ingredients/ingredients.component';
import { arRoutingModule } from './module/ar/ar-routing.module';
import { RestPasswordComponent } from './module/landing-page/rest-password/rest-password.component';
import { RestPasswordComponent as arRestPasswordComponent } from './module/ar/rest-password/rest-password.component';


const routes: Routes = [  
  { path: '', redirectTo: 'landing-page', pathMatch: 'full' },
  { path: '', loadChildren: () => import('./module/landing-page/landing-page.module').then(m => m.LandingPageModule) },
  { path: 'patiner', loadChildren: () => import('./module/partner/partner.module').then(m => m.PartnerModule) },
  { path: 'ar', loadChildren: () => import('./module/ar/ar.module').then(m => m.ArModule) },
  { path: 'admin', loadChildren: () => import('./module/admin/admin.module').then(m => m.AdminModule) },
  { path: 'dashboard', loadChildren: () => import('./module/authenticate/authenticate.module').then(m => m.AuthenticateModule) },
  { path: 'recommendations', component: LandingPageRecPageComponent },
  { path: 'ar/recommendations', component: ArRecPageComponent },
  { path: 'search', component: LandingPageSellectedPageComponent },
  { path: 'ar/search', component: ArSellectedPageComponent },
  { path: 'about-us', component: enAboutUsComponent },
  { path: 'product-page', component: enProductPageComponent },
  { path: 'Product-prices', component: enProductPricesComponent },
  { path: 'ar/Product-prices', component: arProductPricesComponent },
  {path: 'ar/partner', component: arPartnerComponent },
  { path: 'ar/about-us', component: arAboutUsComponent },
  { path: 'ar/product-page', component: arProductPageComponent },
  { path: 'ar/page-not-found', component: arProductNotFoundComponent },
  { path: 'page-not-found', component: enProductNotFoundComponent },
  { path: 'user-profile', component: enUserProfileComponent },
  { path: 'ar/user-profile', component: arUserProfileComponent },
  { path: 'ar/ingrdeints', component: arIngredientsComponent },
  { path: 'ingrdeints', component: enIngredientsComponent },
  { path: 'rest-password', component: RestPasswordComponent },
  { path: 'ar/rest-password', component: arRestPasswordComponent },
  { path: '**', redirectTo: 'page-not-found' }
];
@NgModule({
  imports: [RouterModule.forRoot(routes),
    // arRoutingModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
