import { ViewportScroller } from "@angular/common";
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DEFAULT_PARAMS } from 'src/app/enum/constraints';
import { Component, Input, OnInit,ChangeDetectorRef, ViewChild, ElementRef, Output, EventEmitter, } from '@angular/core';
import { debounceTime, distinctUntilChanged, fromEvent, map } from 'rxjs';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Application } from 'src/app/enum/application.enum';
import { AuthHttpServiceService } from '../services/auth-http-service.service';
import { DashboardServiceService } from '../../authenticate/dashboard-service.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { environment } from "src/environments/environment";
import { Router } from '@angular/router';
import { LoginComponent } from "../login/login.component";
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/shared/services/common.service';
import { AuthService } from '../../../core/auth.service';
import { Title } from '@angular/platform-browser';
import { GoogleTagManagerService } from 'angular-google-tag-manager';


@Component({
  selector: 'app-product-page',
  templateUrl: './product-page.component.html',
  styleUrls: ['./product-page.component.scss']
})
export class ProductPageComponent implements OnInit {
  price: any;
  sortBy: string = 'asc';
  sale: any;
  logo: any;
  link: any;
  store: any;
  Datacprices: any;
  id: any;
  priceDatanew: any[];
  priceId: any;
  idproduct: any;
  perfumedata2: any;
  image: any;
  storedData: any;
  name: any;
  brand: any;
  Discountprice: any;
  product_id: any;
  id_product: any;
  websiteName: string;
removeFromFavorites($event: string) {
throw new Error('Method not implemented.');
}
  @Input() totalCount: number;
  fav: any;
  userid = localStorage.getItem("user_id");
  @ViewChild('specificButton') specificButton: ElementRef;
  super_admin = localStorage.getItem("is_super_admin");
  showWishlist: boolean = false;
selectedProducts: any[];
  toggleWishlist() {
    this.showWishlist = !this.showWishlist;
  }
  average: number;
  showfav = false;
  linkElement: HTMLElement;
  counter = 0;
  @Output() productAdded = new EventEmitter<void>();
  addfav = [];
  @Input() pageSize: number = 10;
  selcetperfume = true;
  isLoggedIn: boolean;
  showArrow = true;
  firstlogin = true;
  isLoggedin = true;
  lowPricesFilter: boolean;
  highPricesFilter: boolean;
  theContent= true;
  isClicked: string = '';
  infoPerfume= true;
  showRec= true;
  maxPages: number = 0;
  params = DEFAULT_PARAMS;
  FilterOption: boolean;
  minValue;
  matchedValues = [];
  priceData: any;
  idprodact = [];
  perfumeData: any;
  constructor(public service: DashboardServiceService,private readonly AuthService: AuthService,private ref: ChangeDetectorRef, public modalService: BsModalService,private readonly toastService: ToastService, private scroller: ViewportScroller, private router: Router
    ,public commonService: CommonService, private route: ActivatedRoute, private readonly authHttpService: AuthHttpServiceService,private readonly formBuilder: UntypedFormBuilder, private readonly DashboardServiceService: DashboardServiceService,  public modalRef: BsModalRef,private titleService: Title, private gtmService: GoogleTagManagerService ) {
      this.isLoggedIn = AuthService.isUserLoggedIn();

     }

  ngOnInit(): void {  
    this.titleService.setTitle('Product page - Dona AI');
  this.route.queryParams.subscribe(params => {
   this.idproduct  = params['id'];
   this.id_product  = params['cid'];
      this.getStorePerfumeData2();
    this.getStorePerfumeData();
    this.otherprice(this.id_product)
       
})
  }
  setSortOrder(order: string) {
    this.sortBy = order; 
    this.priceDatanew = this.priceData.filter(item => item._id !== this.idproduct);
    if (this.sortBy === 'asc') {
      this.priceDatanew.sort((a, b) => a.price - b.price); 
    } else if (this.sortBy === 'desc') {
      this.priceDatanew.sort((a, b) => b.price - a.price); 
    }
  }

  otherprice(product_id){
    if (!product_id) {
      this.service.otherprice(this.id_product, this.params.page_num, this.pageSize)
        .subscribe((res) => {
  
      this.priceData = res.price_data;
  
      if (this.priceData && Array.isArray(this.priceData)) {
        this.priceDatanew = this.priceData.filter(item => item._id !== this.idproduct);
      }        
          this.totalCount = res.Total_count;
          this.average = res.average_rating;
          this.maxPages = Math.ceil(this.totalCount / this.pageSize);
        });
  
        const prices = this.perfumeData && this.perfumeData['product._id'];
      
    }else{
      this.service.otherprice(this.id_product, this.params.page_num, this.pageSize)
      .subscribe((res) => {      
    this.priceData = res.price_data;
    if (this.priceData && Array.isArray(this.priceData)) {
      this.priceDatanew = this.priceData.filter(item => item._id !== this.idproduct);
    }        
        this.totalCount = res.Total_count;
        this.average = res.average_rating;
        this.maxPages = Math.ceil(this.totalCount / this.pageSize);
      });
  
      const prices = this.perfumeData && this.perfumeData['product._id'];
    }
  }
  
  getStorePerfumeData() {
    this.service.priceDetailsbyid_edit(this.idproduct).subscribe(
      (res: any) => {
        if (res) {
          this.Datacprices = res;
          this.id = this.Datacprices._id;
          this.price = this.Datacprices.price;
          this.sale = this.Datacprices.sale;
          this.link = this.Datacprices.link;
          this.store = this.Datacprices.store;           
        }

        const url = new URL(this.link);
        let siteName = url.hostname.replace('.com', ''); // Remove the TLD (.com)
        siteName = siteName.replace('www.', ''); // Remove any leading 'www.'
        this.websiteName = siteName;
        document.getElementById("websiteName").innerText = this.websiteName;
        

  
      },
      (error) => {
        this.router.navigate(['/page-not-found']);
      }
    );
  }
  clearClick(){
    
  }

  getStorePerfumeData2() {
    this.service.dataperfume(this.id_product).subscribe((res: any) => {
      if (res) {        
        this.perfumedata2 = res;
        this.image = this.perfumedata2.image_url
        this.name = this.perfumedata2.name
        this.brand = this.perfumedata2.brand
        ;
      }
    });
  }

  extractNumber(priceString) {
    return priceString;
  }



    Perfumeinfo(data,id) {
      window.open(data.link, "_blank");
      this.trackEvent('Product Link', 'Click', data.link, data.name, data.store, data._id);
      const gtmTag = {
        event: 'button-click link',
        data: 'my-custom-event',
        link: data.link,
        name: data.name,
        store: data.store,
        id_product: data._id
      };
      this.gtmService.pushTag(gtmTag);
      if (!this.isLoggedIn) {
        this.modalRef = this.modalService.show(LoginComponent, {
            initialState: {},
            class: 'perfume-edit-modal'
        });
    } else {
    
    }
    }

  idfav(id) {
    return this.addfav.some(product => product?._id === id);
  }

  clickuser(event) {
    event.preventDefault();
    this.linkElement = this.specificButton.nativeElement.querySelector('a');
    var link = this.linkElement.getAttribute("href");
    this.trackEvent('Product Link', 'Click', link, this.name, this.websiteName, this.idproduct);
    const gtmTag = {
      event: 'button-click link',
      data: 'my-custom-event',
      link: link,
      name: this.name,
      store: this.store,
      id_product: this.idprodact
    };
    this.gtmService.pushTag(gtmTag);

    window.open(link, "_blank");
    if(!this.isLoggedIn){
        this.modalRef = this.modalService.show(LoginComponent, {
            initialState: {},
            class: 'perfume-edit-modal'
        });
    } else {
    }
}
  removeProductFromFavorites(productId: string): void {
    const productIndex = this.selectedProducts.findIndex(product => product.id === productId);

    if (productIndex !== -1) {
      this.selectedProducts.splice(productIndex, 1);
      localStorage.setItem('selectedProducts', JSON.stringify(this.selectedProducts));
      this.ref.detectChanges();
    }
  }



  closesection(){
    this.showfav = !this.showfav;
  }
  opensection(){
    this.showfav = true;
  }

  toggleFilter() {
    this.FilterOption = !this.FilterOption
  }
  handleClick(option: string): void {
    if (this.isClicked === option) {
      // If the clicked option is already selected, deselect it
      this.isClicked = null;
      this.clearClick(); // Assuming you want to clear any filtering or sorting
    } else {
      // Otherwise, select the clicked option
      this.isClicked = option;
      if (option === 'LowPrices') {
        this.setSortOrder('asc');
      } else if (option === 'HighPrices') {
        this.setSortOrder('desc');
      } else if (option === 'Discount') {
        this.Discount();
      }
    }
  }


  lowPricesFilters(){
    this.lowPricesFilter = true;
  }

  Discount() {
    if (this.priceData && Array.isArray(this.priceData)) {
      this.priceDatanew = this.priceData.filter(item => item.sale !== item.price && item._id !== this.idproduct);
    } 
  }
  

  highPricesFilters(){
    this.highPricesFilter = true;
  }

  trackEvent(eventCategory: string, eventAction: string, eventLabel: string, productName: string, partnerName: string, ProductID: string) {
    this.gtmService.pushTag({
      event: 'partner_website_click',
      eventCategory,
      eventAction,
      eventLabel,
      productName,
      partnerName,
      ProductID
    });
  }
}
