import { ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { HttpService } from './core/http.service';
import { ToastService } from './shared/services/toast.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Dona'; 
  loaderStatus = false;
  readonly destroyed$ = new Subject();
  constructor(
    private readonly cdRef: ChangeDetectorRef,
    private readonly toastService: ToastService,
    private readonly httpService: HttpService,private readonly router: Router){
      
    }
  ngAfterViewChecked() {
    this.httpService.diaplayLoader$.pipe(takeUntil(this.destroyed$)).subscribe(res => {
      this.loaderStatus = res;
      if (!this.cdRef['destroyed']) {
        this.cdRef.detectChanges();
      }
    });
  }
  ngOnDestroy() {
    this.destroyed$.complete();
  }
}
