import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Application } from '../enum/application.enum';
import { ToastService } from '../shared/services/toast.service';
import { Router } from '@angular/router';
import { CommonService } from '../shared/services/common.service';
import { VALIDATION_MESSAGE } from '../enum/constraints';
import { HttpService } from './http.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(private readonly toastService: ToastService, private readonly router: Router, private readonly commonService: CommonService,private httpService :HttpService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            switch (err.status) {
                case Application.FOUR_HUNDRED_THREE:
                    this.commonService.clearLocalStorage();
                    this.router.navigate(['/admin/login']);
                    this.toastService.showError(VALIDATION_MESSAGE.API_401_ERROR);
                    break;
                case Application.FIVE_HUNDRED:
                case Application.FIVE_HUNDRED_TWO:
                    this.manageToastFiveError(err);
                    break;
                case Application.ZERO:
                    // this.toastService.showError('Something went wrong. Try again');
                    break;
                default:
                    // this.manageToastError(err);
                    break;
            }
            const error = err.error;
            this.httpService.loaderToggle(false);
            return throwError(error.detail);
        }));
    }
  //   manageToastError(err) {
  //     if (err.error && (err.error.detail || err.error.details) || err.error.data) {
  //         if (err.error.detail !== 'Product already exists in favorites') {
  //             this.toastService.showError(err.error.detail || err.error.details || err.error.data);
  //         }
  //     }
  // }
  

    manageToastFiveError(err) {
        if (err.error && err.error.detail) {
            this.toastService.showError(err.error.detail);
        } else {
            this.toastService.showError(err.status === Application.FIVE_HUNDRED ? VALIDATION_MESSAGE.API_500_ERROR : VALIDATION_MESSAGE.API_502_ERROR);
        }
    }

}
