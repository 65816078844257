<div class="section">
  <hr>
  <footer id="contact">
    <div class="footer desktop">
      <div class="footer-link">
      <p><a>© دونا 2023 -2024  </a> </p>
      <!-- <p><a [href]="['/privacy']"> الخصوصية </a></p>
      <p><a [href]="['/terms']"> الشروط </a></p> -->
      <!-- <p><a [href]="['/ar/about-us']"> عن دونا</a></p>  -->
      <p><a [href]="['/ar/partner']"> اعمل معنا </a></p>
      <p><a class="text-with-underline" [href]="['/']"> English</a></p>
    </div>
    </div>
  </footer>  
  </div>

<!-- اختر عطرك, اختيار عطور, انسب عطر, افضل عطر, عطور نيش, تقنية عطور, رشح عطر, ترشيح عطور, عطر مرشح, نظام ترشيح عطور, اختبار عطور, عطرك المفضل, عطر, عطور, الذكاء الاصطناعي للعطور, عطور الاعلى مبيعًا, متجر عطور -->
<!-- مساعدة في اختيار عطر, عطر على ذوقي, رشح لي عطر, كيف أختار عطر, اختر عطرك, اختيار  عطور, انسب عطر, افضل عطر, عطور نيش, تقنية عطور, رشح عطر, ترشيح عطور, عطر مرشح, نظام ترشيح عطور, اختبار عطور, عطرك المفضل, عطر, عطور, الذكاء الاصطناعي للعطور, عطور الاعلى مبيعًا, متجر عطور -->