<app-navbar></app-navbar>
<div class="page-not-found">
  <div class="container">
    <P>عذرًا، لم يتم العثور على الصفحة الطلوبة</P>
    <button class="btn primary pl-25 pr-25 mb-10 height40" (click)="home()"  #specificButton>
      اعد استخدام دونا
    </button>
  </div>
</div>
<!-- <app-chat-widght></app-chat-widght> -->
<app-footer></app-footer>

<!-- اختر عطرك, اختيار عطور, انسب عطر, افضل عطر, عطور نيش, تقنية عطور, رشح عطر, ترشيح عطور, عطر مرشح, نظام ترشيح عطور, اختبار عطور, عطرك المفضل, عطر, عطور, الذكاء الاصطناعي للعطور, عطور الاعلى مبيعًا, متجر عطور -->
<!-- مساعدة في اختيار عطر, عطر على ذوقي, رشح لي عطر, كيف أختار عطر, اختر عطرك, اختيار  عطور, انسب عطر, افضل عطر, عطور نيش, تقنية عطور, رشح عطر, ترشيح عطور, عطر مرشح, نظام ترشيح عطور, اختبار عطور, عطرك المفضل, عطر, عطور, الذكاء الاصطناعي للعطور, عطور الاعلى مبيعًا, متجر عطور -->