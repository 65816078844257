export const VALIDATION_MESSAGE = {
    REQUIRED: 'This field is required.',
    EMAIL_REQUIRED: 'Email address is required',
    EMAIL_REQUIRED_AR: 'يجب عليك ادخال برديك الالكتروني',
    NAME_REQUIRED: 'Your name is required',
    NAME_REQUIRED_AR: 'يجب عليك ادخال الاسم',
    WEBSITE_REQUIRED :'Website domain is required',
    PASSWORD_REQUIRED : ' Password is required',
    PASSWORD_REQUIRED_AR : ' يجب عليك ادخال كلمة المرور',
    CURRENT_PASSWORD_REQUIRED : 'Current Password is required',
    NEW_PASSWORD_REQUIRED : 'New Password is required',
    CONFIRM_PASSWORD_REQUIRED : 'Confirm Password is required',
    COPY_ID : 'copy id successfully',
    INVALID_EMAIL: 'Email is invalid.',
    INVALID_EMAIL_AR: 'البريد الالكتروني غير صحيح',
    INVALID_PHONE: 'Phone is invalid.',
    INVALID_PHONE_AR: 'رقم الهاتف غير صحيح',
    INVALID_NAME: 'Name is invalid.',
    INVALID_NAME_AR: 'الاسم غي صحيح',
    INVALID_PASSWORD: 'Password is invalid.',
    INVALID_PASSWORD_FORMAT: 'The password should have atleast an uppercase, a number, a special character and contains 8 characters.',
    CONFIRM_PASSWORD_ERROR: 'The confirm password you\'ve entered does not match.',
    INVALID_FIRST_NAME: 'First name is invalid.',
    INVALID_LAST_NAME: 'Last name is invalid.',
    INVALID_BUSINESS_NAME: 'Organization name is invalid.',
    INVALID_INTEGRATION_NAME: 'Integration name is invalid.',
    INVALID_FILE_SIZE: 'File is too large. Only 5MB is allowed.',
    INVALID_FILE_FORMAT: 'Invalid file type.',
    STATE_CITY_REQUIRED: 'State and city is required',
    PROFILE_SUCESS: 'Profile data saved.',
    OTP_REQUIRED: 'OTP Required',
    PHONE_REQUIRED: 'Phone Number is invalid',
    PHONE_REQUIRED_AR: 'يحب عليك ادخال رقم الهاتف',
    INVALID_CAMPAIGN: 'Campaign name is invalid.',
    RESET_PASSWORD: 'Password should contain uppercase, spacial character and numeric value with max length of 8',
    API_401_ERROR : 'Please login again',
    API_500_ERROR : 'Something went wrong please try again',
    API_502_ERROR : 'Server is down, please try after some time',
    STORE_DELETED : ' Store deleted successfully',
    CONFIRM_PASSWORD : 'Confirm Password did not match',
    CHANGE_PASSWORD: 'Reset password successfully',
    SEND_INVITATION : 'Send invitation successfully',
    DELETE_PERFUME : 'Perfume deleted successfully',
    EDIT_SUCCESSFULLY  : 'Perfume updated successfully',
    ADD_PERFUME : 'Perfume added successfully',
    ADD_Admin : 'Admin added successfully',
    CRETEUSERACCOUNT : 'User account created successfully',
    CRETEUSECLIENTE : 'client created successfully',
    DELETE_PARTNER :' Partner deleted successfully',
    DELETE_EMAIL :' email deleted successfully',
    DELETE_USER :' user deleted successfully',
    DELETE_EVENT :' event deleted successfully',
    DELETE_PRICE :' price deleted successfully',
    DELETE_CHAT :' chat deleted successfully',
    STORE_ADD : 'Store Add successfully',
    DELETE_INVITE:'  Invite deleted successfully',
    STORE_EDIT :'Store updated successfully',
    DELETE_DATA :'dellete data successfully'
 };

export const DEFAULT_PARAMS = {
    page_num:  1,
    limit:1000,
    page_size:10,

};



export const MONTH_NAME =[
    {value:1,name :'January'},
    {value:2,name :'February'},
    {value:3,name :'March'},
    {value:4,name :'April'},
    {value:5,name :'May'},
    {value:6,name :'June'},
    {value:7,name :'July'},
    {value:8,name :'August'},
    {value:9,name :'September'},
    {value:10,name :'October'},
    {value:11,name :'November'},
    {value:12,name :'December'},
]

// export const  LANGUAGE ={
//     ENGLISH : 'ENGLISH',
//     ARABIC:'ARABIC'
// }

export const REPORTTYPE =[
    {value:'monthly',name :'monthly'},
    {value:'quarterly',name :'quarterly'},
    {value:'bi-annually',name :'bi-annually'},
]


export const Type = [
  'scraping',
  'file',
];

export const Status = [
  'False',
  'True',
];

export const delete_reason = [
  { name: 'Added by mistake', name_ar: 'تمت اضافته عن طريق الخطا' },
  { name: 'I no longer like it', name_ar: 'لم أعد أحبه' },
  { name: 'I got bored of the perfume', name_ar: 'مللت من العطر' },
];
