import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Component, OnInit, } from '@angular/core';
import { DashboardServiceService } from '../../authenticate/dashboard-service.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/shared/services/common.service';
import { AuthService } from '../../../core/auth.service';

@Component({
  selector: 'app-ingredients',
  templateUrl: './ingredients.component.html',
  styleUrls: ['./ingredients.component.scss']
})
export class IngredientsComponent implements OnInit {
  idproduct: any;
  ingredient: any;
  isLoggedIn: boolean;
  constructor(public service: DashboardServiceService,private readonly AuthService: AuthService, public modalService: BsModalService, private router: Router
    ,public commonService: CommonService, private route: ActivatedRoute,   public modalRef: BsModalRef) {
      this.isLoggedIn = AuthService.isUserLoggedIn();

     }

  ngOnInit(): void {  
  this.route.queryParams.subscribe(params => {
   this.idproduct  = params['id'];
      this.getingredient();
       
})
  }



  getingredient() {
    this.service.ingredient_list(this.idproduct).subscribe((res: any) => {
      if (res) {        
        this.ingredient = res.products_data;
        // console.log(this.ingredient)
        ;
      }
    });
  }

}
