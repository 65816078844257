import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild, ViewEncapsulation , } from '@angular/core';
import { Router } from '@angular/router';
import { VALIDATION_MESSAGE } from 'src/app/enum/constraints';
import { BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import { ToastService } from 'src/app/shared/services/toast.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthHttpServiceService} from '../../landing-page/services/auth-http-service.service';
import { AuthService } from '../../../core/auth.service';
import { DashboardServiceService } from '../../authenticate/dashboard-service.service';
import { REGEX } from 'src/app/enum/validation';

@Component({
  selector: 'app-deleteacc',
  templateUrl: './deleteacc.component.html',
  styleUrls: ['./deleteacc.component.scss']
})
export class DeleteaccComponent implements OnInit {
  error = false;
  login= false;
  data;
  isUserLoggedIn = false;
  show = false;
  localnotaEmail;
  existsEmail = true;
  isLoggedIn: boolean;
  validationMessage = VALIDATION_MESSAGE;
  deleteaccount: UntypedFormGroup;
  constructor(private readonly formBuilder: UntypedFormBuilder ,
    public modalRef: BsModalRef,
    private ref: ChangeDetectorRef,
    public readonly service:DashboardServiceService ,
    public modalService: BsModalService,
    private readonly router: Router,
    public readonly authService: AuthHttpServiceService, 
    public commonService: CommonService, 
    private readonly toastService: ToastService,
    private readonly AuthService: AuthService ) { 
    this.isLoggedIn = AuthService.isUserLoggedIn();
    }

    ngOnInit(): void {
      this.deleteaccount = this.formBuilder.group({
        email: ['', [Validators.required, Validators.pattern(REGEX.EMAIL)]],
        }
        );
    }


  deleteaccountfun() {
      let email = {
        email: this.deleteaccount.value.email,
      }  
      this.service.deleteaccountuser(this.data,this.deleteaccount.value.email)
      .subscribe((response) => {
        this.signout()
      }, (error) => {
        const errorMessage = error.toString(); 
        if (errorMessage.includes('The provided email does not match the stored email for this user.')) {
          this.existsEmail = true; 
          this.existsEmail = !this.existsEmail; 
        } 
      }      
      );
    }

    signout() {
      localStorage.clear();
      this.isUserLoggedIn = false;
      this.router.navigateByUrl('/ar');
      location.reload();
    }

}
