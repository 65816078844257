import { Component, EventEmitter, OnInit, OnDestroy, Output, ViewChild, ElementRef, HostListener } from '@angular/core';
import { DashboardServiceService } from '../../authenticate/dashboard-service.service';
import { forkJoin, Subscription, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from '../../../core/auth.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DeleteProductComponent } from '../delete-product/delete-product.component';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit, OnDestroy {
  images: string[] = [];
  Products: any[] = [];
  modalRef: BsModalRef;
  id = localStorage.getItem('user_id');
  listuser: any;
  mainNoteArray: string[] = [];
  mainNoteArrayName: string[] = [];
  subNoteArray: string[] = [];
  NotesArray: { notes: string }[] = [];
  NotesArrayID: { notes: string }[] = [];
  dataarray: any[] = [];
  imageUrls: any[] = [];
  datanotes: any;
  isLoggedIn: boolean;
  isLoading = true;
  showWishlistContent = false;
  @Output() productAdded = new EventEmitter<void>();
  @ViewChild('collageCanvas', { static: false }) collageCanvas: ElementRef<HTMLCanvasElement>;
  @ViewChild('tooltip', { static: false }) tooltip: ElementRef<HTMLDivElement>;
  private userlistSubscription: Subscription | undefined;
  imagePositions: any[] = [];
  nodata: boolean;
  animationFrameId: number | undefined;
  noteItemDetails: any[];
  mainNoteDetails: any[];
  subNoteDetails: any[];
  mostFrequentSubNotes: string[];
  mostFrequentMainNotes: string[];

  constructor(
    public service: DashboardServiceService,
    private readonly AuthService: AuthService,
    private router: Router,
    public modalService: BsModalService,
    private titleService: Title
  ) {
    this.isLoggedIn = AuthService.isUserLoggedIn();
  }

  ngOnInit(): void {
    this.titleService.setTitle('الملف الشمي - Dona AI');
    this.getuserlist(this.id);

    if (!this.isLoggedIn) {
      this.router.navigate(['/ar']);
    }
  }

  ngOnDestroy(): void {
    if (this.userlistSubscription) {
      this.userlistSubscription.unsubscribe();
    }
    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId);
    }
  }

  ingredints(id: string): void {
    this.router.navigateByUrl(`/ar/ingrdeints?id=${id}`);
  }

  getuserlist(id: string): void {
    this.userlistSubscription = this.service.getuserlist(id).pipe(
      mergeMap((response: any) => {
        this.listuser = response;
  
        if (!this.listuser.unique_products || this.listuser.unique_products.length === 0) {
          this.nodata = true;
          this.router.navigate(['/ar']);
          this.Products = [];
          return of([]);
        } else {
          this.nodata = false;
          const selectProductsList: string[] = this.listuser.unique_products;
          const selectProductsStr = selectProductsList.join(',');
          const requests = this.service.productsDetails(selectProductsStr);
          return forkJoin(requests);
        }
      }),
      catchError(error => {
        console.error('Error fetching products:', error);
        this.nodata = true;
        return of([]);
      })
    ).subscribe(
      (products: any[]) => { 
        if (this.nodata) {
          this.Products = [];
        } else {
          this.Products = products;
          const flattenedArray = this.Products.flat();
          this.Products = flattenedArray;
  
          // Initialize arrays to collect IDs
          const mainNoteIDs: string[] = [];
          const subNoteIDs: string[] = [];
          const noteItemIDs: string[] = [];
  
          // Collect IDs from mainNote, subNote, and notes
          const Notes: any[] = this.Products.map(data => ({
            mainNote: data.mainNote,
            notes: data.notes,
            subNote: data.subNote
          }));
  
          Notes.forEach((data) => {
            const { mainNote, subNote, notes } = data;
  
            if (mainNote && mainNote._id && !mainNoteIDs.includes(mainNote._id)) {
              mainNoteIDs.push(mainNote._id);
            }
  
            if (subNote && subNote._id && !subNoteIDs.includes(subNote._id)) {
              subNoteIDs.push(subNote._id);
            }
  
            if (Array.isArray(notes)) {
              notes.forEach((noteItem) => {
                if (noteItem._id && !noteItemIDs.includes(noteItem._id)) {
                  noteItemIDs.push(noteItem._id);
                }
              });
            }
          });
  
          // Function to fetch ingredient details in batches
          const fetchIngredientsInBatches = async (ids: string[]) => {
            const batchSize = 10; // Adjust the batch size as needed
            const results = [];
            for (let i = 0; i < ids.length; i += batchSize) {
              const batchIds = ids.slice(i, i + batchSize);
              const batchResults = await this.service.ingredientDetails(batchIds.join(',')).toPromise();
              results.push(...batchResults);
            }
            return results;
          };
  
          // Fetch ingredient details for each type of ID
          Promise.all([
            fetchIngredientsInBatches(mainNoteIDs),
            fetchIngredientsInBatches(subNoteIDs),
            fetchIngredientsInBatches(noteItemIDs)
          ]).then(([mainNoteDetails, subNoteDetails, noteItemDetails]) => {
            // Process and store the results
            // console.log('Main Note Details:', mainNoteDetails);
            // console.log('Sub Note Details:', subNoteDetails);
            // console.log('Note Item Details:', noteItemDetails);
            this. mainNoteDetails =  mainNoteDetails
            this.subNoteDetails = subNoteDetails
            this.noteItemDetails = noteItemDetails

            // Get most frequent items from mainNoteDetails
          const mainNoteCounts = this.countOccurrences(this.mainNoteDetails.map(detail => detail.name_ar));
          this.mostFrequentMainNotes = this.getMostFrequentItems(mainNoteCounts, 2);

          // Get most frequent items from subNoteDetails
          const subNoteCounts = this.countOccurrences(this.subNoteDetails.map(detail => detail.name_ar));
          this.mostFrequentSubNotes = this.getMostFrequentItems(subNoteCounts, 2);

          // console.log('Most frequent main notes:', this.mostFrequentMainNotes);
          // console.log('Most frequent sub notes:', this.mostFrequentSubNotes);

            // Update imageUrls directly from mainNoteDetails
          this.imageUrls = this.mainNoteDetails.map(detail => ({
            image_url: detail.image_url,
            name: detail.name,
            name_ar: detail.name_ar
          }));
          this.drawCollage(); 
          this.isLoading = false;
            
            // Do further processing with the results if needed
          }).catch(error => {
            this.isLoading = false; 
            console.error('Error fetching ingredient details:', error);
          });
        }
      },
      error => {
        console.error('Error in subscription:', error);
      }
    );
  }

  countOccurrences(array: string[]): { [key: string]: number } {
    return array.reduce((acc, item) => {
      acc[item] = (acc[item] || 0) + 1;
      return acc;
    }, {} as { [key: string]: number });
  }
  
  getMostFrequentItems(counts: { [key: string]: number }, topN: number): string[] {
    return Object.keys(counts)
      .sort((a, b) => counts[b] - counts[a])
      .slice(0, topN);
  }


  drawCollage() {
    const canvas = this.collageCanvas.nativeElement;
    const ctx = canvas.getContext('2d');
    if (!ctx) return; // Ensure ctx is not undefined

    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;
    const radius = canvas.width / 2;
    const innerCircleRadius = radius * 0.4; // Radius of the white inner circle

    // Draw white inner circle
    ctx.save();
    ctx.beginPath();
    ctx.arc(centerX, centerY, innerCircleRadius, 0, 2 * Math.PI, false);
    ctx.fillStyle = 'white';
    ctx.fill();
    ctx.restore();

    if (this.imageUrls.length === 0) return; // Ensure there are images to process

    const angleStep = (2 * Math.PI) / this.imageUrls.length;
    this.imagePositions = [];

    this.imageUrls.forEach((imgSrc, index) => {
      const angle = index * angleStep;
      this.animateImageInCircle(ctx, imgSrc.image_url, centerX, centerY, radius, innerCircleRadius, angle, index);
    });
  }

  animateImageInCircle(ctx: CanvasRenderingContext2D, imgSrc: string, x: number, y: number, outerRadius: number, innerRadius: number, angle: number, index: number) {
    const image = new Image();
    image.src = imgSrc;
    image.onload = () => {
      let scale = 0;
      const maxScale = 1.2;
      const scaleStep = 0.02;

      const drawFrame = () => {
        ctx.save();
        ctx.beginPath();
        ctx.arc(x, y, outerRadius, angle, angle + Math.PI * 2 / this.imageUrls.length, false);
        ctx.arc(x, y, innerRadius, angle + Math.PI * 2 / this.imageUrls.length, angle, true);
        ctx.closePath();
        ctx.clip();

        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height); // Clear canvas for animation effect
        ctx.drawImage(image, x - outerRadius * scale, y - outerRadius * scale, outerRadius * 2 * scale, outerRadius * 2 * scale);
        ctx.restore();

        scale += scaleStep;
        if (scale <= maxScale) {
          this.animationFrameId = requestAnimationFrame(drawFrame);
        } else {
          this.imagePositions.push({ image_url: imgSrc, x, y, outerRadius, angle });
        }
      };

      drawFrame();
    };
  }

  getImagePosition(x: number, y: number, radius: number, angle: number, angleStep: number) {
    const x1 = x + radius * Math.cos(angle);
    const y1 = y + radius * Math.sin(angle);
    const x2 = x + radius * Math.cos(angle + angleStep);
    const y2 = y + radius * Math.sin(angle + angleStep);

    return { x1, y1, x2, y2 };
  }

  @HostListener('mousemove', ['$event'])
  onCanvasMouseMove(event: MouseEvent) {
    const canvas = this.collageCanvas.nativeElement;
    const rect = canvas.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    const hoveredImage = this.imagePositions.find(pos => this.isPointInCircle(x, y, pos));
    const tooltip = this.tooltip.nativeElement;

    if (hoveredImage) {
      tooltip.style.display = 'block';
      tooltip.style.left = `${event.clientX + 10}px`;
      tooltip.style.top = `${event.clientY + 10}px`;
      tooltip.textContent = hoveredImage.name;
    } else {
      tooltip.style.display = 'none';
    }
  }

  isPointInCircle(px: number, py: number, pos: any) {
    const dx = pos.x1 - px;
    const dy = pos.y1 - py;
    const distance = Math.sqrt(dx * dx + dy * dy);

    return distance < this.collageCanvas.nativeElement.width / 2;
  }
  

  addFavorites(data: any): void {
    const prodact_id = data._id;
    this.showWishlistContent = !this.showWishlistContent;
    this.service.addToFavorites(this.id, prodact_id ).subscribe(
      response => {
        this.productAdded.emit();
      },
      error => {
        if (error.error.detail === 'Product already exists in favorites') {
          console.error('Product already exists in favorites');
        } else {
          console.error('Failed to add to favorites:', error);
        }
      }
    );
  }

  openModal(id?: string) {
    this.modalRef = this.modalService.show(DeleteProductComponent, {
      initialState: {
        data: id
      },
      class: 'perfume-edit-modal'
    });
  }
}
