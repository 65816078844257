<div class="login-modal-nota-nota after-login-modal-transparent"> 
  <div class="login-page">
    <div class="login-head mb-20">
      <img (click)="modalRef.hide()"
        src="/assets/icons/close-icon.svg" alt="Close icon" />
    </div>
    <div class="login-body">
     <h6 >هل انت متاكد من حذف حسابك في دونا؟</h6>
     <h6 >بعد ان تقوم بحذف حسابك لن تستطيع الوصول لتفضيلاتك للاسعار</h6>
     <div *ngIf="!existsEmail" class="exists-email">
      <p>عنوان البريد الالكتروني الذي ادخلته غير متطابق مع حسابك في دونا</p>
    </div>
      <div class="advisor-slide-nota-nota">
        <form [formGroup]="deleteaccount" (submit)="deleteaccount.valid && deleteaccountfun()">
            <div class="form-floating  {{(deleteaccount.controls['email'].dirty && deleteaccount.controls['email'].invalid)?'invalid-form-group':''}}">
              <input type="email" class="form-control" formControlName="email" placeholder="name@example.com">
              <label for="floatingInput">ادخل بريدك الالكتروني</label>
                <div class="feedback"  *ngIf="deleteaccount.controls['email'].dirty && deleteaccount.controls['email'].hasError('pattern')">{{validationMessage.INVALID_EMAIL_AR}}</div>
                   <div class="feedback "  *ngIf="deleteaccount.controls['email'].dirty && deleteaccount.controls['email'].hasError('required')">{{validationMessage.EMAIL_REQUIRED_AR}}</div>
            </div>
          <div class="text-align-right">
            <button type="submit" class="btn primary pl-25 pr-25 btn-login" 
             >حذف حسابي</button>
          </div>
        </form>
      </div>
    </div>
  </div>
 </div>

 