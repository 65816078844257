<div class="login-modal-nota-nota after-login-modal-transparent"> 
  <div class="login-page">
    <div class="login-head mb-20">
      <img (click)="modalRef.hide()" src="/assets/icons/close-icon.svg" alt="Close icon" />
    </div>
    <div class="login-body">
      <h5>Why do you want to delete this perfume?</h5>
      <div class="advisor-slide-nota-nota">
        <form [formGroup]="deleteform" (submit)="deleteform.valid && deleteProsuct()">
          <div class="form-floating">
            <div class="form-floating">
              <label for="selectType">Select the reason</label>
          <select id="selectType" class="form-control"  formControlName="reason" placeholder="reason">
            <option *ngFor="let type of delete_reason"  [value]="type.name">{{type.name}}</option>
          </select>
        </div>
        </div> 
        <div class="text-align-right">
          <button type="submit" class="btn primary pl-25 pr-25 btn-login">Delete the Perfume</button>
        </div>
        </form>
      </div>
  </div>
</div>
