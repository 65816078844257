
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild, ViewEncapsulation , } from '@angular/core';
import { Router } from '@angular/router';
import { VALIDATION_MESSAGE } from 'src/app/enum/constraints';
import { BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import { REGEX } from 'src/app/enum/validation';
import { ToastService } from 'src/app/shared/services/toast.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { environment } from 'src/environments/environment';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators, AbstractControlOptions } from '@angular/forms';
 import { AuthHttpServiceService} from '../services/auth-http-service.service';
 import { AuthService } from '../../../core/auth.service';
import { DashboardServiceService } from '../../authenticate/dashboard-service.service';
import { DeleteaccComponent } from '../deleteacc/deleteacc.component';
import {Application} from "../../../enum/application.enum";
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  styles:[`
    background-color : backgroundColour
    `
  ]
})
export class NavbarComponent implements OnInit {
  showfav = false;
  showArrow = true;
  passmsg: string;
  firstlogin = true;
  isLoggedin = true;
  existsEmail = true;
  forgetpass = false;
  changePassword = false;
  passwordCreated = false;
  selectedProducts: any[] = []
  forgetPasswordForm: UntypedFormGroup;
  data;
  id;
  validationMessage = VALIDATION_MESSAGE;
  password1;
  show = false;
  userID = localStorage.getItem("user_id");
  access_token = localStorage.getItem("access_token");
  user_email = localStorage.getItem("email");
  user_phone = localStorage.getItem("user_phone");
  user_name = localStorage.getItem("user_full_name");
  public imageUrl: string = environment.imageUrl.replace(/\/$/, "");
  public backgroundColour;
  modalRef: BsModalRef;
  menuOption: boolean;
  langOption: boolean;
  userOption: boolean;
  profileOption: boolean;
  addNewUserForm: UntypedFormGroup;
  localnotaEmail;
  edit = false;
  notlogin = false;
  error = false;
  signINSuccessfully: any = true;
  menuCrossIcon = false;
  isUserLoggedIn = false;
  showWishlistContent: boolean = false;
  perfumeAdvertiser: UntypedFormGroup;
  loginForm: UntypedFormGroup;
  forgettpassForm: UntypedFormGroup;
  changePasswordForm: UntypedFormGroup;
  editForm: UntypedFormGroup;
  CreateuserFrm: UntypedFormGroup;
  loginModal: boolean = false;
  password2;
  password3;
  show1 = false;
  show2 = false;
  login= false;
  islogin= false;
  isLoggedIn: boolean;
  formInitialization() {
    this.CreateuserFrm = this.formBuilder.group({
    full_name: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.pattern(REGEX.EMAIL)]],
    phone: ['', [Validators.required]],
    password: ['', [Validators.required]],
    }
    );
    this.changePasswordForm = this.formBuilder.group({
      code: ['', [Validators.required ]],
      new_password: ['', [Validators.required] ],
      confirm_password: ['', [Validators.required]]
    },{validator: this.passwordMatchValidator} as AbstractControlOptions
    );
    this.forgetPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(REGEX.EMAIL)]]
    });
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(REGEX.EMAIL)]],
      password: ['', Validators.required],
      rememberMe: false
    }
    );
    this.editForm = this.formBuilder.group({
      full_name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.pattern(REGEX.EMAIL)]],
      phone: ['', [Validators.required]],
    }
    );
    this.forgettpassForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(REGEX.EMAIL)]],
    }
    );
    
  }
  onClick() {
    if (this.password1 === 'password') {
      this.password1 = 'text';
      this.show = true;
    } else {
      this.password1 = 'password';
      this.show = false;
    }
  }

  constructor(private readonly formBuilder: UntypedFormBuilder ,private ref: ChangeDetectorRef,public readonly service:DashboardServiceService ,
     public modalService: BsModalService, private readonly router: Router,public readonly authService: AuthHttpServiceService, public commonService: CommonService, private readonly toastService: ToastService,private readonly AuthService: AuthService, ) { 
      this.isLoggedIn = AuthService.isUserLoggedIn();
     }
     ngOnInit(): void {
      this.formInitialization();
      if (localStorage.getItem("user_id")) {
        this.service.UserData(this.userID).subscribe(
          (data: any) => {
            this.editForm.patchValue({
              full_name: data.full_name,
              email: data.email,
              phone: data.phone
            });
          },
          (error) => {
            // this.signout();
            // this.loginforms();
          }
        );
      }
  
    }
  wishlist(){
    this.showWishlistContent = !this.showWishlistContent;
  }
  menuOpen() {
    setTimeout(() => {
      this.menuCrossIcon = !this.menuCrossIcon;
    }, 500);
  }

  Createuserform() {
    let data = {
      full_name: this.CreateuserFrm.value.full_name,
      email: this.CreateuserFrm.value.email,
      phone: this.CreateuserFrm.value.phone,
      password: this.CreateuserFrm.value.password,
    }  
    this.service.creatuser(data)
    .subscribe((response) => {
      this.commonService.setLocalStorage(response);
      this.login = !this.login;  
      const email = this.CreateuserFrm.value.email;
      const password = this.CreateuserFrm.value.password;
      this.loginautomatically(email, password);
    }, (error) => {
      const errorMessage = error.toString(); 
      if (errorMessage.includes('The user with this email already exists in the system.')) {
        this.existsEmail = true; 
        this.existsEmail = !this.existsEmail; 
      } 
    }
    
    
    
      
    );
  }


  loginform() {
    const formData: any = new FormData();
    formData.append("username", this.loginForm.value.email);
    formData.append("password", this.loginForm.value.password);
    formData.append("scope", "dona");

    this.service.loginuser(formData)
      .subscribe((response) => {
          location.reload()
          if (response) {
            localStorage.setItem("email", this.loginForm.value.email);
            localStorage.setItem("user_id", response.user_id);

            this.commonService.setLocalStorage(response)
            this.commonService.setLocalStorage(response)
            this.login = !this.login;
            localStorage.setItem("email", this.loginForm.value.email);
            this.localnotaEmail = localStorage.getItem("notaEmail")
            const token = localStorage.getItem("access_token");
            if (token) {
              this.isUserLoggedIn = true;
            }

          }

        },
        (error) => {
          console.error('Login failed', error);
          this.error = true;
          this.firstlogin = false;
        }
      );


  }

  loginautomatically(email: string, password: string) {
    const formData: any = new FormData();
    formData.append("username", email);
    formData.append("password", password);
    formData.append("scope", "dona");

    this.service.loginuser(formData)
      .subscribe((response) => {
          location.reload()
          if (response) {
            localStorage.setItem("email", email);
            localStorage.setItem("user_id", password);
            this.commonService.setLocalStorage(response)
            this.commonService.setLocalStorage(response)
            this.login = !this.login;
            localStorage.setItem("email", email);
            this.localnotaEmail = localStorage.getItem("notaEmail")
            const token = localStorage.getItem("access_token");
            if (token) {
              this.isUserLoggedIn = true;
              localStorage.setItem(Application.TOKEN, token);
            }
          }
        },
        (error) => {
          console.error('Login failed', error);
          this.error = true;
          this.firstlogin = false;
          // Perform any other actions you need in response to the error
        }
      );
  }
  
  closesection(){
    this.showfav = !this.showfav;
  }
  opensection(){
    this.showfav = true;
  }

  Forgotten(){
    this.forgetpass = true;
    this.notlogin = false;
  }


  updateUserData() {
    this.service.updateUserData(this.userID, this.editForm.value)
      .subscribe(
        (response) => {
          this.editForm.reset();
          this.edit = !this.edit;  
        },
        (error) => console.error('فشل في تحديث البيانات:', error)
      );
  }
  
    
  removeProductFromFavorites(productId: string): void {
    const productIndex = this.selectedProducts.findIndex(product => product.id === productId);
  
    if (productIndex !== -1) {
      this.selectedProducts.splice(productIndex, 1);
      localStorage.setItem('selectedProducts', JSON.stringify(this.selectedProducts));
      this.ref.detectChanges();
    }
  }

editProfile(){
  this.edit = !this.edit;
}

signout() {
    localStorage.clear();
    this.signINSuccessfully = false;
    this.isUserLoggedIn = false;
    this.router.navigateByUrl('/');
    location.reload();
  }

  delleteaccount(id?): void {
    this.modalRef = this.modalService.show(DeleteaccComponent , {
      initialState: {
        data: id
      },
    }
    );
    this.edit = !this.edit
  }

  menuAction() {
    this.menuOption = !this.menuOption;
    this.langOption = false;
  }
  usermenu(){
this.profileOption = !this.profileOption;
  }

  SelectedLanguage(){
    this.menuOption = false;
    this.langOption = !this.langOption;
  }
  toggleConversationalView() {
    // this.showConv = !this.showConv;
    this.login = !this.login;
    this.userOption =!this.userOption;
if(this.isUserLoggedIn){
  this.profileOption = !this.profileOption;
}
    // Trigger the change detection
    this.ref.detectChanges();
    
  }
  Creatuser() {
      this.islogin = false;
      this.notlogin = !this.notlogin;
  }
  
  close(){
    this.login = !this.login;
  }

  closeform(){
    this.edit = !this.edit;
  }
  loginforms() {
    this.islogin = !this.islogin;
    this.notlogin= !this.notlogin;
  }

  closepass(){
    this.forgetpass = false;
    this.notlogin = true;
  }
  
  changePass(){  
    this.service.resetPassword(this.changePasswordForm.value)
  .subscribe((response) => {
    if (response){
     this.toastService.showSuccess(VALIDATION_MESSAGE.CHANGE_PASSWORD); 
     this.passwordCreated =  true;
    }
  
  });
}

checkPassSame() {
  let pass = this.changePasswordForm.value.new_password;
  let passConf = this.changePasswordForm.value.confirm_password;
  if(pass == passConf && this.changePasswordForm.valid === true) {
    this.passmsg = "";
    return false;
  }else {
    this.passmsg = this.validationMessage.CONFIRM_PASSWORD;
    return true;
  }
}
passwordMatchValidator(frm: UntypedFormGroup) {
  return frm.controls['new_password'].value === 
  frm.controls['confirm_password'].value ? null : {'mismatch': true};
      }

      Click(pass) {
        if(pass === 'password1'){
          if (this.password1 === 'password') {
            this.password1 = 'text';
            this.show = true;
          } else {
            this.password1 = 'password';
            this.show = false;
          }
        }
        else if(pass === 'password2'){
          if (this.password2 === 'password') {
            this.password2 = 'text';
            this.show1 = true;
          } else {
            this.password2 = 'password';
            this.show1 = false;
          }
        }
        else {
          if (this.password3 === 'password') {
            this.password3 = 'text';
            this.show2 = true;
          } else {
            this.password3 = 'password';
            this.show2 = false;
          }
        }
        }

        forgetPassword(){
          this.service.forgetPassword(this.forgetPasswordForm.value)
            .subscribe((response) => {
              if (response)
              this.router.navigateByUrl('/rest-password');
              this.toastService.showSuccess(response.message);
            });
        }

}
