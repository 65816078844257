import { Component, OnInit } from '@angular/core';
import { VALIDATION_MESSAGE } from 'src/app/enum/constraints';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthHttpServiceService} from '../../landing-page/services/auth-http-service.service';
import { DashboardServiceService } from '../../authenticate/dashboard-service.service';
import { AuthService } from '../../../core/auth.service';
@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.scss']
})
export class SubscribeComponent implements OnInit {
  subSection = true;
  isNotaSubActive: boolean;
  isLoggedIn: boolean;
  userid = localStorage.getItem("user_id");
  validationMessage = VALIDATION_MESSAGE;
  Createsubform: UntypedFormGroup;
  // isNotaSubActive: boolean = false;
  constructor(public readonly service:DashboardServiceService,private readonly formBuilder: UntypedFormBuilder,private readonly AuthService: AuthService,) { 
    this.isLoggedIn = AuthService.isUserLoggedIn();
  }

  formInitialization() {
    this.Createsubform = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }
  ngOnInit(): void {
    this.formInitialization();
    const isHidden = localStorage.getItem('isNotaSubHidden');
    this.isNotaSubActive = isHidden !== 'true';
    if (!this.isNotaSubActive) {
      this.subSection = false;
    }
  }

  closesection() {
    this.subSection = !this.subSection;
    if (!this.subSection) {
      this.isNotaSubActive = false;
      localStorage.setItem('isNotaSubHidden', 'true');
    } else {
      localStorage.setItem('isNotaSubHidden', 'false');
    }
  }

  Createchatforms() {
    if (this.isLoggedIn) {
    let data = {
      user_id: this.userid,
      email: this.Createsubform.value.email,
    };

    this.service.subscribe(data)
    .subscribe((response) => {
      this.subSection = !this.subSection;
    },(error) => {
    })
  }else if(!this.isLoggedIn){
    let data = {
      email: this.Createsubform.value.email,
    };

    this.service.subscribe(data)
    .subscribe((response) => {
      this.subSection = !this.subSection;
    },(error) => {
    })
  }
}

}
