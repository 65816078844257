import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { DashboardServiceService } from '../../authenticate/dashboard-service.service';
import { LoginComponent } from '../login/login.component';
@Component({
  selector: 'app-suggestions',
  templateUrl: './suggestions.component.html',
  styleUrls: ['./suggestions.component.scss']
})
export class SuggestionsComponent implements OnInit {
  suggestedPerfumeFrm: FormGroup;
  userid = localStorage.getItem("user_id");
  public imageUrl: string = environment.imageUrl.replace(/\/$/, "");
  constructor(  public modalRef: BsModalRef,private readonly formBuilder: FormBuilder,public readonly service:DashboardServiceService,public modalService: BsModalService) { }
  
  ngOnInit(): void {
    this.suggestedPerfumeFrm = this.formBuilder.group({
      perfumeName: ['', [Validators.required]],
    });
  }

  suggestedPerfumeAdd() {
    let data = {
      name: this.suggestedPerfumeFrm.value.perfumeName,
      validate_customer_details: this.userid,
      website: window.location.origin
    }
    if (!data.validate_customer_details) {
      this.modalRef.hide(); 
      this.modalRef = this.modalService.show(LoginComponent, {
      });
    }else{
    this.service.suggestedperfume(data)
      .subscribe((response) => {
        this.modalRef.hide()
      })
    }
  }

}
