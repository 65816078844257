<app-navbar></app-navbar>
<div class="body" >
  <div *ngIf="isLoading" class="loader-overlay">
    <div class="loader"></div>
  </div>
  
<section class="search-div">
  <div class="container feature-container" >
    <h4 class="mb-4">ملفك العطري</h4>
    <!-- <p>Welcome to your personal insights. Gain a clearer understanding of yourself, including your preferences and beloved scents</p> -->
    <div id="overview" class="feature-heading">
      <div class="common-search perfume-search search-list-nota-nota">
        <div class="containers">
          <canvas #collageCanvas width="350" height="350"></canvas>
          <div #tooltip class="tooltip"></div>
          <div class="text">
            <div class="text mt-4">
              <p>
                انت تفضل من عائلات العطور
                <span *ngIf="mostFrequentMainNotes && mostFrequentMainNotes.length > 0">
                  {{ mostFrequentMainNotes[0] }}
                </span>
                <span *ngIf="mostFrequentMainNotes && mostFrequentMainNotes.length > 1">
                  و
                  <span class="words">{{ mostFrequentMainNotes[1] }}</span>
                </span>
                <span *ngIf="mostFrequentMainNotes && mostFrequentMainNotes.length > 1"></span>
              
                مع
                <span *ngIf="mostFrequentSubNotes && mostFrequentSubNotes.length > 0">
                  {{ mostFrequentSubNotes[0] }}
                </span>
                <span *ngIf="mostFrequentSubNotes && mostFrequentSubNotes.length > 1">
                  و
                  <span class="words">{{ mostFrequentSubNotes[1] }}</span>
                </span>
                <span *ngIf="mostFrequentSubNotes && mostFrequentSubNotes.length > 1"></span>
              </p>
            </div>          
            
        <div class="box-notes" >
          <h6 class="mb-4">المكونات المفضلة لديك</h6>
          <div class="box-div">
          <div *ngFor="let data of noteItemDetails | slice:0:10">
            <div  class="rounded-full  ">
              <span>{{ data.name_ar }}</span>
            </div>
          </div>
        </div>
              
      </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section>
  <div class="container feature-container">
      <h4 class="mt-4"> تفضيلاتك الشخصية </h4>
      <div dir="ltr" class="top-recommendations" >
        <div class="our-recommended-nota-nota"  *ngFor="let data of noteItemDetails;let i = index"> 
          <div class="perfume-pic">
            <img class="perfume-img"  
              src="{{data.image_url}}"   onerror="this.src=`https://ntoa-nota-public.s3.us-west-2.amazonaws.com/widget/assets/images/perfume2.png`;" alt="Perfume2" />
          </div>
          <div class="perfume-des">
            <div class="nota-info">
              <div class="nota-info-2">
              <div class="nota-nota-tooltip-hover" >
                <a><h6 class="perfume-title" (click)="ingredints(data._id)"> اكتشف العطور التي تحتوي على  {{data.name_ar}}</h6></a>
              </div> 
            </div>
          </div>
          <div >
    
            
    
    
      </div>
    </div>
  </div>
  </div>
</div>
</section>

<section>
  <div class="container feature-container">
    <h4> القائمة الخاصة بك </h4>
    <div dir="ltr" class="top-recommendations" >
      <div class="our-recommended-nota-nota"  *ngFor="let data of Products;let i = index"> 
        <img (click)="openModal(data)"  class="fav-img-rec m-lg-2 mobile-fav" 
        [src]=" '../../../../assets/icons/close-icon.svg'" />
        <div class="perfume-pic">
          <img class="perfume-img"  
            src="{{data.image_url}}"   onerror="this.src=`https://ntoa-nota-public.s3.us-west-2.amazonaws.com/widget/assets/images/perfume2.png`;" alt="Perfume2" />
        </div>
            
        <div class="perfume-des">
        <div class="nota-info">
          <div class="nota-info-2">
          <div class="nota-nota-tooltip-hover" >
            <h6 class="perfume-title"  >{{data.name_ar}}</h6>
          </div>
          <div class="nota-nota-tooltip-hover">
            <p class="perfume-subtitle">{{data.brand_ar}}</p>
          </div>  
        </div>

        
        </div>
      <div >

        


  </div>
</div>
</div>
</div>
</div>
</section>


</div>


<app-footer></app-footer>




