<div>
  <div class="login-modal-nota-nota after-login-modal-transparent"> 
   <div class="login-page">
     <div class="login-head mb-20">
       <!-- <img class="smile-icon" src="{{imageUrl}}/assets/icons/suggest-perfume-icon.svg"
         alt="Perfume icon" /> -->
       <img class="close-icon" (click)="modalRef.hide()"
         src="{{imageUrl}}/assets/icons/close-icon.svg" alt="Close icon" />
     </div>
     <div class="login-body">
       <h6 class="login-title">اقتراح عطر</h6>
       <p class="login-text mb-20">
        يرجى التحقق من اسم العطر الذي تبحث عنه</p>
       <div class="advisor-slide-nota-nota">
         <form [formGroup]="suggestedPerfumeFrm" (submit)="suggestedPerfumeFrm.valid && suggestedPerfumeAdd()">
           <div class="input-group mb-20">
             <input dir="ltr" class="floating-input" type="text" formControlName="perfumeName" required>
             <label class="floating-label">اسم العطر </label>
             <!-- <span class="text-danger" >Please enter perfume name</span>
               <span class="highlight"></span>
               <span class="bar"></span> -->
           </div>
           <div class="text-align-right">
             <button type="submit" class="btn primary pl-25 pr-25"
               [disabled]='suggestedPerfumeFrm.invalid'>ارسال</button>
           </div>
         </form>
       </div>
     </div>
   </div>
  </div>

 </div>