<app-navbar></app-navbar>
<div class="product-page">
<section class="info-perfume">
  <div class="padme-perfume">

        <div class="container  col-lg-8 feature-container padme-perfume">   
          <hr>
          <div class="filter-btn">
            <div>
              <h3 class="mobile-filter">استكشف أسعار عطر {{this.nameperfume}} في المتاجر المختلفة</h3>
            </div>
            <div class="btn-filter">
              <button (click)="toggleFilter()" class="filtter-btn btn primary pl-25 pr-25"><img src="/../../../../assets/images/filter.png" alt="filter" class="filter-img"> </button>
              <ul [class]="FilterOption ? 'user-option-drop show':'user-option-drop'">
                <li>
                  <div class="filter-options" (click)="handleClick('Discount')">
                    <img class="click" alt="icon" [src]="isClicked === 'Discount' ? '../../../../assets/images/click-btn.png' : '../../../../assets/images/click.png'">
                    <p>تخفيضات</p>
                  </div>
                </li>
                <li>
                  <div class="filter-options" (click)="handleClick('LowPrices')">
                    <img class="click" alt="icon" [src]="isClicked === 'LowPrices' ? '../../../../assets/images/click-btn.png' : '../../../../assets/images/click.png'">
                    <p>السعر (من الاقل الى الاعلى)</p>
                  </div>
                </li>
                <li>
                  <div class="filter-options" (click)="handleClick('HighPrices')">
                    <img class="click" alt="icon" [src]="isClicked === 'HighPrices' ? '../../../../assets/images/click-btn.png' : '../../../../assets/images/click.png'">
                    <p>السعر (من الاعلى الى الاقل)</p>
                  </div>
                </li>
                <li>
                  <div class="clear" (click)="handleClick('clear')">
                    <p>الغاء التصفية</p>
                  </div>
                </li>
                
              </ul>
            
            </div>
          </div>
          <div dir="rtl" class="top-recommendations row">
            <div class="our-recommended-nota-nota-other col-md-3" *ngFor="let data of priceDatanew;let i = index">
            <div class="" >
              <div class="perfume-pic perfume-pic-store">
                <img class="perfume-img perfume-img-store"  
                  src="{{image}}"   onerror="this.src=`https://ntoa-nota-public.s3.us-west-2.amazonaws.com/widget/assets/images/perfume2.png`;" alt="Perfume2" />
              </div>
            </div>
            <div class="perfume-des">
              <div class="nota-info">
                <div class="nota-info-2">
                  <div class="nota-nota-tooltip-hover"  [ngClass]=" data['name'] ? '' : 'widget-pointer'">
                    <h6 class="perfume-title"  >{{data["name"]}}</h6>
                  </div>
              
              </div>
              <div class="nota-nota-tooltip-hover dev-prices">
                <p class="perfume-subtitle price">
                    <ng-container *ngIf="data['sale'] && data['sale'] !== data['price']">
                        <span class="original-sale">{{ extractNumber(data['price']) | number: '1.2-2' }} SAR</span>
                        <span class="original-price">{{ extractNumber(data['sale']) | number: '1.2-2' }} SAR</span>
                    </ng-container>
                    <ng-container *ngIf="!data['sale'] || data['sale'] === data['price']">
                        {{ extractNumber(data['price']) | number: '1.2-2' }} SAR
                    </ng-container>
                </p>
            </div>
              </div>
              </div>
              <div class="nota-info">
                <div class="nota-nota-tooltip-hover store">
                  <a><img class=""  
                      src="{{data['logo']}}"  alt="Perfume2" /></a>
                  </div>
                  <button class="add-to-cart btn primary pl-25 pr-25" (click)="clickuser($event, data['link'])" >
                    <a id="productLink" href="{{data['link']}}" target="_blank">احصل عليه الان </a>
                  </button>
                  <!-- <button  class="add-to-cart btn primary pl-25 pr-25"><a href="{{data['link']}}"> احصل عليه الان </a></button> -->
              </div>
              </div>
              <div *ngIf="priceDatanew && priceDatanew.length === 0">
                <p>حاليا, لايوجد تخفيضات لهذا العطر</p>
              </div>
              
            </div>
          </div>
        </div>
          <!-- <app-chat-widght></app-chat-widght> -->
          <app-footer></app-footer>
      

<!-- اختر عطرك, اختيار عطور, انسب عطر, افضل عطر, عطور نيش, تقنية عطور, رشح عطر, ترشيح عطور, عطر مرشح, نظام ترشيح عطور, اختبار عطور, عطرك المفضل, عطر, عطور, الذكاء الاصطناعي للعطور, عطور الاعلى مبيعًا, متجر عطور -->
<!-- مساعدة في اختيار عطر, عطر على ذوقي, رشح لي عطر, كيف أختار عطر, اختر عطرك, اختيار  عطور, انسب عطر, افضل عطر, عطور نيش, تقنية عطور, رشح عطر, ترشيح عطور, عطر مرشح, نظام ترشيح عطور, اختبار عطور, عطرك المفضل, عطر, عطور, الذكاء الاصطناعي للعطور, عطور الاعلى مبيعًا, متجر عطور -->