<app-navbar></app-navbar>
<section id="installation">
  <div class="installation ">
    <div class="installation-container">
      <div class="row installation-heading search-list-nota-nota row feature-row">
        <div>
        <form class=" container">
          <button class="default-pic" *ngIf="showProductonFirstSCreen?.length > 2"
            [disabled]="!(showProductonFirstSCreen.length > 2)"
            [ngClass]="{'black-button': showProductonFirstSCreen.length > 2}"
            (click)="nextscreenfirst(showProductonFirstSCreen)" >التالي</button>
          <div class="form-group mb-20 container">
            <p *ngIf="clickperfume">Pick at least 3 favorites to unlock your match!</p>
            <h3>ابحث عن عطورك المفضلة</h3>
            <input dir="ltr" type="search" placeholder="ابحث باسم العطر" [formControl]="searchInputControl" />
          </div>
         <div class="div-perfume" *ngIf="perfumediv">
          <h3>اضف <span class="highlight"> من 3 الى 5</span> عطورك المفضلة </h3>
          <h3 #alertMessage class="alert-message" *ngIf="more5"> يمكنك فقط اضافة 5 عطور </h3>
          <div class="default-pic-wrp-mobile">
  </div>
          <hr/>
          <div dir="ltr" class="perfume-listing container">
            <div dir="ltr" class="perfume-listing-wrap "
              *ngFor="let data of searchData" (click)="add(data)" [ngClass]="idGetter(data?._id)? 'disableClass' : '' " >
              <div dir="ltr" class="perfume-info">
                <div class="plus-icon"><img alt="Add icon" class="add-arrow"  src="../../../../assets/icons/{{idGetter(data?._id) ? 'click-btn.png' : 'add-per.png'}}"></div>
                <div class="perfume-pic">
                  <img class="right-arrow" src="{{data?.image_url}}"  onerror="this.src='https://ntoa-nota-public.s3.us-west-2.amazonaws.com/widget/assets/images/default.png';"  alt="Perfume pic" />
                </div>
                <div class="perfume-des">
                  <h6 class="perfume-title"  >{{data?.name_ar}} </h6>
                  <p class="perfume-text">{{data?.brand_ar}}</p>
                </div>
              </div>
              <div class="plus-icon">
              <div class="flex flex-row items-center justify-start leading-typeTag font-primary">
          </div>
            </div>
            
            </div>  
            <div class="spinner-nota-nota" *ngIf="loader">
              <div class="loader-nota-nota"></div>
              <div class="loader-overlay-nota-nota"></div>
            </div>

            <div class="width-100">
              <div class="recommended-null" *ngIf="!loader">
                <div class="no-perfume-found request-this-perfume" *ngIf="searchData.length ===0 && noDataFirstTime">
                  <h5 class="no-title">عفوًا.. لم نجد ماتبحث عنه</h5>
                  <p class="no-text">يبدو أن هذا العطر ليس في قائمتنا بعد.</p>
                  <button class="no-btn btn primary pl-25 pr-25"
                     (click)="openSuggest()">أقترح العطور</button>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      
          
         <!-- <app-subscribe></app-subscribe> -->
        </form>
      </div>
  
      </div>
      <div class="row installation-heading installation-heading-arabic search-list-nota-nota row feature-row" >

      <div class="common-search perfume-search col-md-3 feature-left container">
        <!-- <owl-carousel-o [options]="customOptions"> -->
      
          <ng-template carouselSlide>
            <div class="card-wrap" *ngFor="let data of showProductonFirstSCreen;let i = index">                
              <div class="perfume-item">
                <div class="perfume-pic">
                  <img class="perfume-img" src="{{data.image_url}}"    onerror="this.src='https://ntoa-nota-public.s3.us-west-2.amazonaws.com/widget/assets/images/default.png';"  alt="Perfume" />
                  <img class="minus-icon"
                    src="/assets/icons/close-icon.svg" alt="Remove"
                    (click)="remove(data._id, true)" />
                </div>
                <div class="perfume-des">
                  <div class="nota-nota-tooltip-hover">
                    <h6 class="perfume-title">{{data.name}}</h6>
                  </div>
                  <div class="nota-nota-tooltip-hover">
                    <p class="perfume-subtitle">{{data.brand}}</p>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        <!-- </owl-carousel-o> -->
      </div>
  </div>

  
  


    </div>
  </div>

</section>
<!-- 
  <app-chat-widght></app-chat-widght> -->
  <app-footer></app-footer>


<!-- اختر عطرك, اختيار عطور, انسب عطر, افضل عطر, عطور نيش, تقنية عطور, رشح عطر, ترشيح عطور, عطر مرشح, نظام ترشيح عطور, اختبار عطور, عطرك المفضل, عطر, عطور, الذكاء الاصطناعي للعطور, عطور الاعلى مبيعًا, متجر عطور -->
<!-- مساعدة في اختيار عطر, عطر على ذوقي, رشح لي عطر, كيف أختار عطر, اختر عطرك, اختيار  عطور, انسب عطر, افضل عطر, عطور نيش, تقنية عطور, رشح عطر, ترشيح عطور, عطر مرشح, نظام ترشيح عطور, اختبار عطور, عطرك المفضل, عطر, عطور, الذكاء الاصطناعي للعطور, عطور الاعلى مبيعًا, متجر عطور -->
