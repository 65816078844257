
<div  *ngIf="!isLoggedIn && login">
  <div  *ngIf="!islogin">
    <div class="login-modal-nota-nota after-login-modal-transparent"> 
     <div class="login-page">
       <div class="login-head mb-20">
         <img  (click)="close()"
           src="/assets/icons/close-icon.svg" alt="Close icon" />
       </div>
       <div class="login-body">
        <h2>Create your account</h2>
         <h6>Signing up for Dona is fast and free</h6>
         <div class="advisor-slide-nota-nota">

          <div *ngIf="!existsEmail" class="exists-email">
            <p>This email is already registered <a (click)="loginforms()">Log in</a></p>
          </div>
           <form [formGroup]="CreateuserFrm" (submit)="CreateuserFrm.valid && Createuserform()">  
            <div class="form-floating  {{(CreateuserFrm.controls['email'].dirty && CreateuserFrm.controls['email'].invalid)?'invalid-form-group':''}}">
              <input type="email" class="form-control" formControlName="email" placeholder="name@example.com">
              <label for="floatingInput">Email Address</label>
                <div class="feedback"  *ngIf="CreateuserFrm.controls['email'].dirty && CreateuserFrm.controls['email'].hasError('pattern')">{{validationMessage.INVALID_EMAIL}}</div>
                   <div class="feedback "  *ngIf="CreateuserFrm.controls['email'].dirty && CreateuserFrm.controls['email'].hasError('required')">{{validationMessage.EMAIL_REQUIRED}}</div>
            </div>
            <div class="form-floating ">
              <input [type]="password1" formControlName="password"  class="form-control" placeholder="Password">
              <a class="show-password" (click)="onClick()" *ngIf="show"><img class="show-password" src="../../../../assets/icons/eye-icon.svg" alt="Show password"></a>
              <a class="show-password" (click)="onClick()" *ngIf="!show"><img src="../../../../assets/icons/hide-eye-icon.svg" alt="Hide password"></a>
              <label for="floatingPassword">Password</label>
              <div class="feedback"
              *ngIf="CreateuserFrm.controls['password'].dirty &&
              CreateuserFrm.controls['password'].hasError('required')">{{validationMessage.PASSWORD_REQUIRED}}</div>
          </div>
          <div class="form-floating  ">
            <input type="text" class="form-control" pattern="[A-Za-z\s]+" formControlName="full_name" placeholder="your name">
            <label for="floatingInput">Name ( EN )</label>
              <div class="feedback"  *ngIf="CreateuserFrm.controls['full_name'].dirty && CreateuserFrm.controls['full_name'].hasError('pattern')">{{validationMessage.INVALID_NAME}}</div>
                 <div class="feedback "  *ngIf="CreateuserFrm.controls['full_name'].dirty && CreateuserFrm.controls['full_name'].hasError('required')">{{validationMessage.NAME_REQUIRED}}</div>
          </div>
          <div class="form-floating">
            <input type="tel" pattern="[0-9]{10,15}" class="form-control" formControlName="phone" placeholder="your phone">
            <label for="floatingInput">Phone number</label>
              <div class="feedback"  *ngIf="CreateuserFrm.controls['phone'].dirty && CreateuserFrm.controls['phone'].hasError('pattern')">{{validationMessage.INVALID_PHONE}}</div>
                 <div class="feedback "  *ngIf="CreateuserFrm.controls['phone'].dirty && CreateuserFrm.controls['phone'].hasError('required')">{{validationMessage.PHONE_REQUIRED}}</div>
          </div>
            <div class="text-align-right">
              <button type="submit" class="btn primary pl-25 pr-25 btn-login" [style.background-color]='backgroundColour'
              >Sign up</button>
              <h6 class="nav-title">Already have a DONA account? <a (click)="loginforms()">Log in</a></h6>
            </div>
          </form>
         </div>
       </div>
     </div>
    </div>
  </div>
    <div class="login-modal-nota-nota after-login-modal-transparent" *ngIf="notlogin"> 
      <div class="login-page">
        <div class="login-head mb-20">
          <img (click)="close()"
            src="/assets/icons/close-icon.svg" alt="Close icon" />
        </div>
        <div class="login-body">
          <h2>Login to your account</h2>
         <h6 *ngIf="firstlogin">Wellcom back to your account</h6>
         <h6 *ngIf="error" class="error">Incorrect Email Address or Password</h6>
          <div class="advisor-slide-nota-nota">
            <form [formGroup]="loginForm" (submit)="loginForm.valid && loginform()">
                <div class="form-floating  {{(loginForm.controls['email'].dirty && loginForm.controls['email'].invalid)?'invalid-form-group':''}}">
                  <input type="email" class="form-control" formControlName="email" placeholder="name@example.com">
                  <label for="floatingInput">Email Address</label>
                    <div class="feedback"  *ngIf="loginForm.controls['email'].dirty && loginForm.controls['email'].hasError('pattern')">{{validationMessage.INVALID_EMAIL}}</div>
                       <div class="feedback "  *ngIf="loginForm.controls['email'].dirty && loginForm.controls['email'].hasError('required')">{{validationMessage.EMAIL_REQUIRED}}</div>

                </div>
                <div class="form-floating {{(loginForm.controls['password'].dirty && loginForm.controls['password'].invalid)?'invalid-form-group':''}}">
                  <input [type]="password1" formControlName="password"  class="form-control" placeholder="Password">
                  <a class="show-password" (click)="onClick()" *ngIf="show"><img class="show-password" src="../../../../assets/icons/eye-icon.svg" alt="Show password"></a>
                  <a class="show-password" (click)="onClick()" *ngIf="!show"><img src="../../../../assets/icons/hide-eye-icon.svg" alt="Hide password"></a>
                  <label for="floatingPassword">Password</label>
                  <div class="feedback"
                  *ngIf="loginForm.controls['password'].dirty &&
                  loginForm.controls['password'].hasError('required')">{{validationMessage.PASSWORD_REQUIRED}}</div>
              </div>
                <!-- <input dir="ltr" class="floating-input" placeholder="email" type="email" formControlName="email" required> -->
            
              <!-- <a  [href]="['/admin/forget-password']" ><h6 >Forgotten your password ?</h6></a> -->
              <div class="text-align-right">
                <button type="submit" class="btn primary pl-25 pr-25 btn-login" [style.background-color]='backgroundColour'
                 >Log in</button>
                 <h6 class="nav-title">You don't have a DONA account? <a (click)="Creatuser()">Sign up</a></h6>
                 <p (click)="Forgotten()">Forgot your password?</p>
                  <!-- <button class="btn primary pl-25 pr-25 btn-login" [style.background-color]='backgroundColour'
                  (click)="Creatuser()">Creat an account</button> -->
              </div>
            </form>
          </div>
        </div>
      </div>
     </div>
<!-- </div> -->
</div>
<div class="both">
  <div class="container header-wrap">
    <div>
      <nav class="navbar navbar-expand-lg p-0">
        <div class="container-fluid">
          <a class="navbar-brand" href="/">
            <img class="logo" [src]="'../../../../assets/images/logo.png'">
          </a>  
          <div class="" id="navbarNav">
            <ul [ngClass]="menuCrossIcon ? 'navbar-nav menus-wrap' : 'navbar-nav menus-wrap hide-for-mob'">
              <li *ngIf="!isLoggedIn" class="nav-item">
                <div class="nav-link" data-target="#login" data-toggle="modal" (click)="toggleConversationalView()">
                  <img src="../../../../assets/images/icon_user.svg" width="auto" height="auto" alt="user">
                </div>
              </li>
              <li *ngIf="isLoggedIn" class="nav-side nav-item">
                <button (click)="usermenu()" class="add-to-cart btn primary pl-25 pr-25">
                  {{user_name}} <img src="../../../../assets/images/open-menu.png" alt="open-menu">
                </button>
              </li>
              <li *ngIf="isLoggedIn" [class]="profileOption ? 'user-option-drop show' : 'user-option-drop'" class="nav-item">
                <ul>
                  <li><a class="nav-link" (click)="wishlist()">Wishlist</a></li>
                  <li><a class="nav-link" [href]="['/user-profile']">Your Scent Profile </a></li>
                  <app-wishlist *ngIf="showWishlistContent"></app-wishlist>
                  <li><a (click)="editProfile()">Edit your information</a></li>
                  <li><a class="nav-link" data-target="#login" data-toggle="modal" (click)="signout()">Sign Out</a></li>
                </ul>
              </li>
              <li class="nav-item">
                <a class="text-with-underline" [href]="['/ar']">العربية</a>
              </li>
            </ul>
            
        
        
          
            
          </div>
        </div>
      </nav>
    </div>
    
  </div>

</div>



<div class="login-modal-nota-nota after-login-modal-transparent" *ngIf="edit"> 
  <div class="edit-page">
    <div class="login-head mb-20">
      <img  (click)="closeform()"
        src="/assets/icons/close-icon.svg" alt="Close icon" />
    </div>
    <div class="login-body">
      <h3 class="login-title">Edit your information </h3>
      <div class="advisor-slide-nota-nota">
        <form [formGroup]="editForm" (submit)="editForm.valid && updateUserData()">
                <div class="form-floating">
                  <input type="email" class="form-control" placeholder="{{user_name}}" type="text" formControlName="full_name">
                  <label for="floatingInput">Your Name</label>
                </div>
                <div class="form-floating">
                  <input type="email" class="form-control" placeholder="{{user_email}}" type="email" formControlName="email">
                  <label for="floatingInput">Your email Address</label>
                </div>
                <div class="form-floating">
                  <input type="tel" pattern="[0-9]{10,15}" class="form-control" placeholder="{{user_phone}}" type="phone" formControlName="phone">
                  <label for="floatingInput">Your phone number</label>
                </div>
                <p class="remove-account" (click)="delleteaccount(this.userID)">Delete my account</p>
          <div class="text-align-right">
            <button type="submit" class="btn primary pl-25 pr-25 btn-login" [style.background-color]='backgroundColour'
             >Save </button>
          </div>
        </form>
      </div>
    </div>
  </div>
 </div> 

 <div class="login-modal-nota-nota after-login-modal-transparent" *ngIf="forgetpass"> 
  <div class="login-page">
    <div class="login-head mb-20">
      <img  (click)="closepass()"
      src="/assets/icons/close-icon.svg" alt="Close icon" />
  </div>
    <div class="login-body">
     <h6 *ngIf="error" class="error">Incorrect Email Address or Password</h6>
      <div class="advisor-slide-nota-nota">
        <form [formGroup]="forgetPasswordForm" (submit)="forgetPasswordForm.valid &&  forgetPassword()">
          <div class="sign-in-heading">
              <h3 class="">Forgot Password?</h3>
              <p class="auth-short-des">Enter the email address associated with your account and we'll send an email with instructions to reset your password.</p>
          </div>
          <div class="form-floating  {{(forgetPasswordForm.controls['email'].dirty && forgetPasswordForm.controls['email'].invalid)?'invalid-form-group':''}}">
              <input type="email" class="form-control" formControlName="email" placeholder="name@example.com">
              <label for="floatingInput">Email address</label>
                <div class="feedback"  *ngIf="forgetPasswordForm.controls['email'].dirty && forgetPasswordForm.controls['email'].hasError('pattern')">{{validationMessage.INVALID_EMAIL}}</div>
                   <div class="feedback "  *ngIf="forgetPasswordForm.controls['email'].dirty && forgetPasswordForm.controls['email'].hasError('required')">{{validationMessage.REQUIRED}}</div>

            </div>
          <div class="auth-btn">
              <button class="btn primary w-100" type="submit"  [disabled]='forgetPasswordForm.invalid'>Send Instructions</button>
              <div class="mt-4 text-center">
                  <!-- <a >Back to Sign In</a> -->
              </div>
          </div>
      </form>
      </div>
    </div>
  </div>
 </div>

 

<div class="login-modal-nota-nota after-login-modal-transparent" *ngIf="passwordCreated">
  <div class="login-page">
<div class="auth-wrapper" >
  <div class="row auth-row">
      <div class="col-md-6 authbanner">
          <img class="auth-pic" src="../../../../assets/images/kindi-logo-black.png" alt="Auth pic" width="150" height="60"/>
      </div>
      <div class="col-md-6 form-wrap">
          <form class="text-center">
              <div class="email-sent text-center">
                  <img src="../../../../assets/icons/email-sent-icon.svg" alt="Email sent" />
              </div>
              <div class="sign-in-heading">
                  <h3 class="">Password created successfully</h3>
                  <p class="email-short-instruction">You have successfully created your new password Please, use your new password when signing in </p>
              </div>
              <!-- <div class="auth-btn">
                  <button class="btn primary w-100" type="submit"><a [href]="['/admin/login']">Back to sign in</a></button>
              </div> -->
          </form>
      </div>
  </div>
</div>
</div>
</div>
