<app-navbar></app-navbar>
<section>
  <div class="container feature-container">
    <div id="overview" class="feature-heading" >
      <div class="common-tag">
  
      </div>
      <h2 class="feature-title text-black">How do I get serious clients?</h2>
      <img src="../../../../assets/images/black-star.svg" alt="Star" class="black-star"/>
      <img src="../../../../assets/images/black-perfume.svg" alt="Perfume" class="black-perfume"/>
    </div>
  
    <div class="row feature-row" >
      <div class="col-md-5 feature-left">
        <div class="feature-media">
          <div class="d-flex feature-body">
            <div class="flex-shrink-0 feature-img">
              <img src="../../../../assets/images/black-search.svg" alt="Search" class="feature-media-pic">
            </div>
            <div class="flex-grow-1 feature-des">
              <h5> The customer visits DONA website</h5>
              <p>Dona works on acquiring customers as they enter the Dona website through the advertisements placed by Dona.</p>
            </div>
          </div>
        </div>
        <div class="feature-media">
          <div class="d-flex feature-body">
            <div class="flex-shrink-0 feature-img">
              <img src="../../../../assets/images/black-add.svg" alt="Search" class="feature-media-pic">
            </div>
            <div class="flex-grow-1 feature-des">
              <h5>The customer adds their favorite perfumes</h5>
              <p>Dona needs to build a profile of the customer's olfactory taste by having the customer add 3 of their favorite perfumes.</p>
            </div>
          </div>
        </div>
        <div class="feature-media">
          <div class="d-flex feature-body">
            <div class="flex-shrink-0 feature-img">
              <img src="../../../../assets/images/black-checked.svg" alt="Search" class="feature-media-pic">
            </div>
            <div class="flex-grow-1 feature-des">
              <h5>Dona suggests the most suitable perfumes for the customer</h5>
              <p>Dona will suggest a collection of perfumes that best suit the customer's taste, and will display a range of websites that offer the perfume at the specified price</p>
            </div>
          </div>
        </div>
        <div class="feature-media">
          <div class="d-flex feature-body">
            <div class="flex-shrink-0 feature-img">
              <img src="../../../../assets/images/black-checked.svg" alt="Search" class="feature-media-pic">
            </div>
            <div class="flex-grow-1 feature-des">
              <h5>Completing the purchase on your website</h5>
              <p>The customer selects the most suitable website for them, and Dona redirects the customer to your website where they complete the purchase through your online store.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-7 feature-right">
        <img src="../../../../assets/images/feature-pic.png" alt="main-img" class="Feature pic"/>
      </div>
    </div>
  </div>
  </section>

  <section id="installation">
  <div class="installation desktop">
    <div class="common-tag">
      <!-- <span>Overview</span> -->
    </div>
    <h2 class="feature-title text-black">Why DONA ? </h2>
    <div class="container installation-container">
      <div class="row installation-wrap" >
        <div class="col-md-4 card-wrap">
          <div class="card-img">
          </div>
          <a>We assist the customer in choosing perfumes</a>
          <p>
            Dona selects perfumes based on the customer's olfactory preferences.
          </p>
        </div>
        <div class="col-md-4 card-wrap">
          <div class="card-img">
          </div>
          <a>Providing an unparalleled exchange system</a>
          <p>
            If a customer purchases a perfume through Dona's recommendation system, we offer the option to return the perfume and reimburse its value, even if the perfume has been used
          </p>
        </div>
    
        <div class="col-md-4 card-wrap">
          <div class="card-img">
          </div>
          <a>Let us market your products</a>
          <p>
            Dona promotes your fragrance products to suitable customers. By using Dona, you enhance your visibility to a larger customer base.
          </p>
    
        </div>
      </div>
  
      <form action="/action_page.php" style="opacity: 0; position: absolute;">
                <textarea #sharableLink>
                    <base href="/">
                    <Nota-widget >
                    </Nota-widget>
                  </textarea>
        <br>
        <input type="submit" value="Submit">
      </form>
  
    </div>
  </div>
  

  </section> 


  
  <section>
  <div id="faq" class="relax">
    <div class="container relax-container">
      <div class="row relax-wrap" >
        <div class="col-md-6 relax-left">

          <div class="common-tag">
            <span>Frequently Ask Question</span>
          </div>
          <h2 class="relax-title">We are here for you</h2>
          <p>Still have any question please email us at <a href="mailto: info@nota-nota.com">info&#64;nota-nota.com</a></p>
        </div>
        <div class="col-md-6 relax-right">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button class="accordion-button" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        What are the fees for using Dona?
                </button>
              </h2>
              <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                   data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  Dona operates as an affiliate marketer within the "Affiliate Marketing" system, with a commission rate of 5% on purchases directed through the link provided by Dona
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        How is the commission calculated?
                </button>
              </h2>
              <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                   data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  The "Affiliate Marketing" automatically identifies customers directed from the Dona site and calculates the value of purchases made through the link within () days from the date of link usage. Then, it automatically calculates the commission value for Dona
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        What is the idea behind the artificial intelligence system for perfume recommendations?
                </button>
              </h2>
              <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                   data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  Dona's system recommends perfumes to customers by entering the perfumes they prefer. The artificial intelligence system analyzes those perfumes and their olfactory characteristics using Dona's database, then suggests 3 perfumes from the database with olfactory characteristics preferred by the customer.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  </section> 
  <app-footer></app-footer> 


    <!-- Choose your fragrance, Select your perfume, Select your fragrance, Best perfume choice, Perfumes best seller, Niche perfumes, Perfumes stores, Fragrance stores, Perfumes technology, Perfumes recommendations, Perfumes recommendations system, AI for perfumes -->
  <!-- Perfume assistant, choose perfume, Choose fragrance, Select perfume, Select fragrance, Best perfume choice, perfumes best seller, niche perfumes, perfumes stores, Fragrance stores, perfumes technology, perfumes recommendations, perfumes recommendations system, AI for perfumes -->