import { Injectable } from '@angular/core';
import { Application } from '../enum/application.enum';
import { CommonService } from '../shared/services/common.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor( private readonly commonService: CommonService,) { }
  /**
   * @description get JWT Token from local storage.
   */
   static getToken() {
    return localStorage.getItem(Application.TOKEN);
  }

  /**
   * @description check user login status
   */
  isUserLoggedIn(): boolean {
    const loadedUser = this.commonService.getLocalStorageItem(Application.TOKEN);
    if (loadedUser) {
      return true;
    }
    return false;
  }
  


  /**
   * @description logout user
   */
  logout() {
    localStorage.removeItem(Application.TOKEN);
  }
}
